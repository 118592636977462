import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation } from 'react-router-dom';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

class ModalTemplate extends Component {

	constructor(props) {
    super(props);
    this.state = {modalId: "selectsection"};
  }


	render() {
    let width = this.props.imageWidth !== undefined ? this.props.imageWidth + 20 + "px" : "500px";
    let btnList = [];
    btnList = this.props.btnForm.map(
      (k, n) => {
            return (
                <MDBBtn key={n} type="btn" className={k.classname} onClick={k.onclick}>{k.text}</MDBBtn>
            );
          }
    );

    return (
    <div className="modaltemplate-scss">
      <MDBModal id={this.props.modalId} tabIndex='-1' show={this.props.showModal}>
        <MDBModalDialog centered style={{maxWidth: width}}>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{this.props.modalTyp}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={this.props.hideModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {this.props.children}
            </MDBModalBody>
            <MDBModalFooter>
              {btnList}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
  }
}
export default ModalTemplate;
