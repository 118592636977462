import React, { Component, useContext, useState, useRef, useEffect, useCallback  } from "react";
import { Outlet } from 'react-router-dom';
import { Navigate, Link, useLocation } from 'react-router-dom';
import AppContext from '../../AppContext'
import hamburger from "../../assets/media/icons/Hamburger.svg";

import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBBtn,
  MDBIcon
} from 'mdb-react-ui-kit';

class SideNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};

  };


  componentDidMount() {

  }

  lastPathNameElement = () => {
    return window.location.pathname.split("/")[window.location.pathname.split("/").length-1]
  }

  truncateString = (str, num) => {
		if(str !== null){
			if (str.length <= num) {
				return str
	  	}
	  			return str.slice(0, num) + '...'
		}
	}

  render(){
    let shortlinkHome = this.props.homePage[0]!== undefined ? this.props.homePage[0].shortlink : "";
    let itemList = this.props.otherpagesList.filter((k,n) => (n<6)).map((k,n) =>
      {
        return (
          <MDBSideNavItem key={n}>
            <Link to={'/page/' + k.shortlink} onClick={() => this.props.setSidenavOpen(!this.props.sidenavOpen)}>
              {k.name}
            </Link>
          </MDBSideNavItem>
        );
      }
    )
    let categorylist = this.props.categorisLis.map((k,n) => {
      return (
        <Link id="dropdown-text" to={'/categorypage?categoryId=' + k.id} onClick={() => this.props.setSidenavOpen(!this.props.sidenavOpen)} key={n}><span className="dropdown-text">{k.title}</span></Link>
      );

    }
    )
    return (
      <>
        <MDBSideNav isOpen={this.props.sidenavOpen} getOpenState={(e: any) => this.props.setSidenavOpen(e)} closeOnEsc backdrop={false}>
        <MDBSideNavMenu>
          <MDBSideNavItem className="pt-3">
            <Link to={'/' + shortlinkHome} onClick={() => this.props.setSidenavOpen(!this.props.sidenavOpen)}>
              Home
            </Link>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink icon='angle-down' shouldBeExpanded={this.props.basicCollapse1} onClick={() => this.props.setBasicCollapse1(!this.props.basicCollapse1)}>
              <span>Categories</span>
            </MDBSideNavLink>
            <MDBSideNavCollapse show={this.props.basicCollapse1}>
              {categorylist}
            </MDBSideNavCollapse>
          </MDBSideNavItem>
          {itemList}
        </MDBSideNavMenu>
        </MDBSideNav>
        <div className='text-center' ref={this.props.contentRef}>
          <div className='text-center'>
            <MDBBtn onClick={() => this.props.setSidenavOpen(!this.props.sidenavOpen)} className='m-0 hamburger-btn'>
              <img src={hamburger}/>
            </MDBBtn>
          </div>
        </div>


    </>
    );
  }
}

export default function SideNavbarFunc(props) {
    const context = useContext(AppContext);
    const [basicCollapse1, setBasicCollapse1] = useState(false);
    const [sidenavOpen, setSidenavOpen] = useState(true);
    const [basicCollapse2, setBasicCollapse2] = useState(true);
    const contentRef = useRef(null);
    let location = useLocation();
    return <SideNavbar context={context}
                       contentRef={contentRef}
                       basicCollapse1={basicCollapse1}
                       setBasicCollapse1={setBasicCollapse1}
                       basicCollapse2={basicCollapse2}
                       setBasicCollapse2={setBasicCollapse2}
                       sidenavOpen={sidenavOpen}
                       setSidenavOpen={setSidenavOpen}
                       location={location}
                       showSideNavbar={props.showSideNavbar}
                       onshowSideNavbar={props.onshowSideNavbar}
                       hideSideNavbar={props.hideSideNavbar}
                       navbarPaddingTop={props.navbarPaddingTop}
                       otherpagesList={props.otherpagesList}
                       categorisLis={props.categorisLis}
                       homePage={props.homePage}/>;
}
