import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../AppContext';

import Moment from 'moment';
import 'moment/locale/de';

import ViewCard from '../components/Cards/ViewCard.js'


import addSmall from "../assets/media/icons/Add Small.svg";
import arrowLeft from "../assets/media/icons/arrow-circle-left.png";
import edit from "../assets/media/icons/Edit.svg";
import trash from "../assets/media/icons/Trash-red.svg";
import addNew from "../assets/media/icons/Add New.svg";
import question from "../assets/media/logo/Danfoss-logo.png";
import discontinued from "../assets/media/icons/DISCONTINUED.svg";

import { MDBContainer,
         MDBRow,
         MDBCol
       } from 'mdb-react-ui-kit';
import { MDBSortable, MDBSortableElement } from 'mdb-react-drag-and-drop';
class CategoryPage extends Component {

  constructor(props) {
    super(props);

    this.state = {productList: [], category: {}};

  }

  componentDidMount() {
    this.onGetCategoryById(this.props.searchParams.get("categoryId"))
    this.onGetProductsFromCategory(this.props.searchParams.get("categoryId"));
  }

  componentDidUpdate(prewProps, prewState) {
    if(this.state.category.id !== parseInt(this.props.searchParams.get("categoryId"))){
      this.onGetCategoryById(this.props.searchParams.get("categoryId"))
      this.onGetProductsFromCategory(this.props.searchParams.get("categoryId"));
    }
  }

  onGetCategoryById = (id) => {
    let self = this;
    this.props.context.interAxios.get('/category/' + id, function(response) {
      self.setState({category: response.data});
    })
  }

  onGetProductsFromCategory = (id) => {
    let self = this;
    this.props.context.interAxios.get('/category/' + id + '/product-summary', function(response) {
      self.setState({productList: response.data.filter(k => k.status)});
    })
  }

  truncateString = (str, num) => {
    if(str !== null){
      if (str.length <= num) {
        return str
      }
          return str.slice(0, num) + '...'
    }
  }
  /*<MDBCol md="4" key={n} className="p-0 d-flex justify-content-center">
  <div className="viewCard-link">
    <Link to={urlText + k.id}>
    <ViewCard url={k.firstImage !== null ? k.firstImage.url : ""} title={k.title} description={k.description} status={"categoryCard"}/>
    </Link>
  </div>
  </MDBCol>*/
  render(){

    let urlText= "/productpage?categoryId=" + this.props.searchParams.get("categoryId") + "&productId=";
    return (
      <div style={{width: "100%", marginTop: "20px"}} className="px-2">
      <MDBContainer>
        <MDBRow className="m-0 pb-4">
          <MDBCol md="4" className="p-0">
            {this.state.category.image !== undefined && this.state.category.image !== null ?
              <img
                src={this.state.category.image.url}
                className=' img-thumbnail img-fluid hover-shadow'
                alt=''
              />
            :
            <div className="quetion-card-products d-flex align-items-center justify-content-center">
                <img src={question}/><br/>
            </div>}
          </MDBCol>
          <MDBCol md="8">
            <MDBRow className="m-0 py-3">
              <span className="detail-text" dangerouslySetInnerHTML={{__html: this.state.category.description}} />
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow className="m-0 py-3">
          <span className="title-text p-0">Products in this category</span>
        </MDBRow>
        <MDBRow className="m-0">
          {this.state.productList.map((k, n) => {
            return(
              <MDBCol md="12" key={n} className="p-0 d-flex justify-content-center">
              <Link to={urlText + k.id} style={{width: "100%"}}>
              <div className="pb-3">
              <MDBRow className="m-0 pb-3" style={{borderBottom: "1px solid #D8D8D9"}}>
                <MDBCol md="2" className="p-0">
                  <div className="photo-area-client">
                    {k.available ?<>
                      {k.firstImage === null ?
                        <div className="quetion-card-products-search d-flex align-items-center justify-content-center">
                          <img src={question} style={{opacity: 1}}/><br/>
                        </div>:
                        <img
                          src={k.firstImage.url}
                          className=' img-thumbnail img-fluid hover-shadow'
                          style={{opacity: 1}}
                          alt=''
                        />
                      }
                    </>:<>
                      {k.firstImage === null ?
                        <div className="quetion-card-products-search d-flex align-items-center justify-content-center" style={{opacity: .2}}>
                          <img src={question} style={{opacity: .3}}/><br/>
                        </div>:
                        <img
                          src={k.firstImage.url}
                          className=' img-thumbnail img-fluid hover-shadow'
                          style={{opacity: .3}}
                          alt=''
                        />
                      }
                    </>}
                  </div>
                </MDBCol>
                <MDBCol md="10" className="p-0">
                  <MDBRow className="m-0 pb-2">
                    <span className="title-text">{k.title}</span>
                  </MDBRow>
                  <MDBRow className="m-0">
                    <span className="detail-text-search" dangerouslySetInnerHTML={{__html: this.truncateString(k.description, 200)}} />
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              </div>
              </Link>
              </MDBCol>
            )
          })}
        </MDBRow>
      </MDBContainer>
      </div>


    );
  }
}
export default function CategoryPageFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const nodeRef = React.useRef(null)
    return <CategoryPage context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef}/>;
}
