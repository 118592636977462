import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';

import Moment from 'moment';
import 'moment/locale/de';

import DocumentSectionView from '../../../components/SectionView/DocumentSectionView.js'
import LinkSectionView from '../../../components/SectionView/LinkSectionView.js'
import TextSectionView from '../../../components/SectionView/TextSectionView.js'
import VideoSectionView from '../../../components/SectionView/VideoSectionView.js'
import SoftwareSectionView from '../../../components/SectionView/SoftwareSectionView.js'
import VideoComponent from "../section/VideoSection";
import UrlComponent from "../section/UrlSection";
import DocumentComponent from "../section/DocumentSection";
import TextComponent from "../section/TextSection";
import ImagesComponent from '../../../components/ImagesComponent/ImagesComponent.js'
import ViewCard from '../../../components/Cards/ViewCard.js'


import { MDBContainer,
         MDBRow,
         MDBCol,
         MDBBtn,
         MDBModal,
         MDBModalDialog,
         MDBModalContent,
         MDBModalHeader,
         MDBModalTitle,
         MDBModalBody
       } from 'mdb-react-ui-kit';



class ViewPage extends Component {

  constructor(props) {
    super(props);
    this.state = {product: {}, selectedFiles:[]}

  }


  componentDidMount() {
    this.onGetProduct(this.props.product);
  }

  componentDidUpdate(prewProps, prewState) {
  }

	componentWillUnmount() {
	}




  onGetProduct = (product) => {
    let newArray = [];
    for(let i=0; i<product.sections.length; i++){
      newArray[i]=product.sections[i];
    }
    for(let i=0; i< newArray.length; i++){
      if(!newArray[i].trashBin){
        for(let i=0; i< newArray.length; i++){
            if(newArray[i].sectionType === TextComponent){
              newArray[i].sectionType = "TEXT";
            }
            if(newArray[i].sectionType === VideoComponent){
              newArray[i].sectionType = "VIDEO";
            }
            if(newArray[i].sectionType === UrlComponent){
              newArray[i].sectionType = "LINK";
            }
            if(newArray[i].sectionType === DocumentComponent){
              newArray[i].sectionType = "DOCUMENT";
            }
        }
      }else{
        if(newArray[i].trashBin){
          newArray.splice(i, 1)
        }
      }
    }
    let productFinal = {  title: product.title,
                          descriptionTitle: product.descriptionTitle,
                          description: product.description,
                          sections: newArray,
                          status: true,
                          software: product.software !== undefined && product.software.length>0 ? product.software : []
                        };
      this.setState({product: productFinal,
                     selectedFiles: product.images !== undefined ?  product.images : []});

  }

  render(){
    return (
      <>
      <MDBModal show={this.props.showModal} tabIndex='-1' >
        <MDBModalDialog size='xl' scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Preview</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={this.props.hideModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {this.state.product !== undefined ? <>
              <MDBContainer>
                <div/>
                <section className="overview">
                  <div className="wrapper">
                  {this.state.selectedFiles !== undefined && this.state.selectedFiles.length > 0 ?
                    <ImagesComponent array={this.state.selectedFiles}/>:false}
                    <MDBRow className="m-0 pb-2">
                    <span className="title-text p-0">{this.state.product.descriptionTitle}</span>
                    </MDBRow>
                    <div  id="bullet-point">
                    <p className="detail-text" dangerouslySetInnerHTML={{__html: this.state.product.description}}/>
                    </div>
                  </div>
                </section>
              </MDBContainer>

              {this.state.product.sections !== undefined ? <>
              <MDBContainer>
                {this.state.product.sections.filter((k) => (k.sectionType === "TEXT")).length > 0 ? <>
                <div id="text-section-id"/>

                <section className="text-section">
                  <TextSectionView array={this.state.product.sections.filter((k) => (k.sectionType === "TEXT"))}/>
                </section>
                </>:false}
              </MDBContainer>


              {this.state.product.sections.filter((k) => (k.sectionType === "LINK")).length > 0 ? <>
              <div id="link-section-id"/>
              <section className="link-section">
                <LinkSectionView array={this.state.product.sections.filter((k) => (k.sectionType === "LINK"))}/>
              </section></>:false}

              <MDBContainer>
                {this.state.product.sections.filter((k) => (k.sectionType === "DOCUMENT")).length > 0 ? <>
                <div id="document-section-id"/>
                <section className="document-section">
                  <DocumentSectionView arrayDocuments={this.state.product.sections.filter((k) => (k.sectionType === "DOCUMENT"))
                        .map((k) => ({id: k.id, name: k.name, sectionType: k.sectionType, type: k.type !== null ? k.type : "Document", url: k.url, validForCountries: k.validForCountries, validForCountriesMultiplicity: k.validForCountriesMultiplicity, language: k.language, validFor: k.validFor}))
                        .concat(this.state.product.sections.filter((k) => (k.sectionType === "VIDEO"))
                        .map((k) => ({id: k.id, name: k.name, sectionType: "DOCUMENT", type: "Video", url: k.url, validForCountries: "All countries", validForCountriesMultiplicity: "Multiple"})))

                        }/>
                </section></>:false}
              </MDBContainer>

              {this.state.product.software.length > 0 ? <>
              <div id="video-section-id"/>
              <section className="video-section">
                <SoftwareSectionView array={this.props.array}/>
              </section></>:false}

              </>:false}
              </>:false}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      </>

    );
  }
}
export default function ViewPageFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const nodeRef = React.useRef(null)
    return <ViewPage context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef}
                     showModal = {props.showModal} product = {props.product} array={props.array} hideModal = {props.hideModal}/>;
}
