


import React, { Component, useContext, useState  } from "react";
import { Outlet } from 'react-router-dom';
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import AppContext from '../AppContext'

import danfossLogo from "../assets/media/logo/Danfoss logo.svg";

import {MDBRow, MDBContainer, MDBCol
} from 'mdb-react-ui-kit';

class NotMobileDevicePage extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};
  };

	componentDidMount() {
	}

	componentWillUnmount() {
	}

  render() {
    return (
			<>
				<MDBContainer fluid className="mx-0 px-0 d-flex align-items-center" style={{backgroundColor: "#E10210", height: "100vh"}}>
					<MDBRow center className="m-0 pb-3">
            <MDBCol md="12" className="pb-4 d-flex align-items-center justify-content-center">
              <img src={danfossLogo} style={{width: "250px"}}/>
            </MDBCol>
            <MDBCol md="12" className="d-flex align-items-center justify-content-center">
              <span className="title-text text-center" style={{color: "#fff"}}>This page is on mobile device not available!</span>
            </MDBCol>
					</MDBRow>
				</MDBContainer>
	 		</>
 	);
 }
 }

 export default  NotMobileDevicePage;
