import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import ModalTemplate from "../ModalTemplate";
import AppContext from '../../../../AppContext';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSelect,
  MDBCol,
  MDBRow
} from 'mdb-react-ui-kit';


class DeleteCategoryModal extends Component {

	constructor(props) {
    super(props);
    this.state = {isMoveProducts: false, popUpList: []};
  }

  componentDidMount() {
    let popUpCategory = [{text: "(Choose a category)", value: -1, defaultSelected: true}];
    for(let i=0; i<this.props.categorisLis.length; i++){
      if(this.props.categorisLis[i].title !== this.props.categoryName){
        popUpCategory.push({text: this.props.categorisLis[i].title, value: this.props.categorisLis[i].id})
      }
    }
    this.setState({popUpList:popUpCategory})
  }



  internalHandleSave = () => {
    let self = this;
    if(this.state.isMoveProducts && this.state.anotherCategoryId !== undefined) {
      this.props.context.interAxios.put('/category/' + this.props.categoryId + '/move-products-to-category/' + this.state.anotherCategoryId, function(response) {
        self.onDeleteCategory();
      })
    }
    if(!this.state.isMoveProducts) {
      self.onDeleteCategory();
    }
  }

  onDeleteCategory = () => {
    let self = this;
    this.props.context.interAxios.delete('/category/' + this.props.categoryId, function(response) {
      self.props.hideModal();
    })
  }

  onMoveProducts = () => {
    this.setState({isMoveProducts: !this.state.isMoveProducts})
  }

  toAnotherCategory = (id) => {
    this.setState({anotherCategoryId: id!==-1 ? id : undefined})
  }



	render() {

    let btnForm= [  {classname: !this.state.isMoveProducts ? "save delete-category-btn" : "save" ,
                     onclick: this.internalHandleSave ,
                     text: !this.state.isMoveProducts ? "Delete without moving the products" : "Confirm"},
                    {classname: "cancel" ,
                     onclick: !this.state.isMoveProducts ? this.props.hideModal : this.onMoveProducts ,
                     text: !this.state.isMoveProducts ? "Cancel" : "Back"}
           ]
    let btnList = [];
    btnList = btnForm.map(
      (k, n) => {
            return (
                <MDBBtn key={n} type="btn" className={k.classname} onClick={k.onclick}>{k.text}</MDBBtn>
            );
          }
    );
    //console.log(this.state.anotherCategoryId);
    return (
      <div className="modaltemplate-scss">
        <MDBModal id="" tabIndex='-1' show={this.props.showModal} >
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>{this.props.modalTyp}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={this.props.hideModal}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                {this.props.textend}
                {this.state.isMoveProducts ?
                <MDBRow center className="py-3">
                  <div style={{width: "50%"}}>
                  <MDBSelect id="deleteCategory" onValueChange={(e) => this.toAnotherCategory(e.value)} data={this.state.popUpList} visibleOptions={3}/>
                  </div>
                </MDBRow>:false}
              </MDBModalBody>
              <MDBModalFooter>
              {!this.state.isMoveProducts ?
              <MDBCol>
                <MDBBtn type="btn" className="save" onClick={this.onMoveProducts}>Yes</MDBBtn>
              </MDBCol>:false}
                {btnList}
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
  );
  }

}
export default function DeleteCategoryModalFunc(props) {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    let location = useLocation();
    return <DeleteCategoryModal context={context}
                                navigate={navigate}
                                hideModal={props.hideModal}
                                showModal={props.showModal}
                                modalTyp={props.modalTyp}
                                categoryId={props.categoryId}
                                categoryName={props.categoryName}
                                textend={props.textend}
                                categorisLis={props.categorisLis}/>;
}
