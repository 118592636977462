import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../AppContext';

import Moment from 'moment';
import 'moment/locale/de';

import DocumentSectionView from '../components/SectionView/DocumentSectionView.js'
import LinkSectionView from '../components/SectionView/LinkSectionView.js'
import TextSectionView from '../components/SectionView/TextSectionView.js'
import VideoSectionView from '../components/SectionView/VideoSectionView.js'

import ImagesComponent from '../components/ImagesComponent/ImagesComponent.js'
import ViewCard from '../components/Cards/ViewCard.js'


import addSmall from "../assets/media/icons/Add Small.svg";
import arrowLeft from "../assets/media/icons/arrow-circle-left.png";
import edit from "../assets/media/icons/Edit.svg";
import trash from "../assets/media/icons/Trash-red.svg";
import addNew from "../assets/media/icons/Add New.svg";

import { MDBContainer,
         MDBNavbar,
         MDBNavbarNav,
         MDBNavbarItem,
         MDBNavbarLink,
         MDBTable,
         MDBTableHead,
         MDBTableBody,
         MDBRow,
         MDBCol,
         MDBBtn,
         MDBCard,
         MDBCardBody,
         MDBCardTitle,
         MDBCardText,
         MDBInput,
         MDBTextArea,
         MDBFile,
       } from 'mdb-react-ui-kit';



class OtherPage extends Component {

  constructor(props) {
    super(props);
    this.listInnerRefOverview = React.createRef();
		this.listInnerRefTextsection = React.createRef();
		this.listInnerRefLinksection = React.createRef();
    this.listInnerRefDocumentsection = React.createRef();
    this.listInnerRefVideosection = React.createRef();
    this.listInnerRefRelatedProducts = React.createRef();
    this.state = {other: {},
                  selectedFiles:[],
                  navbarPaddingTop: true,
                  activeItem: 0,
                  navbaritems: [],
                  otherOffsetHeight: document.getElementById('otherpageNavbar-id')?.firstChild.offsetHeight};

  }


  componentDidMount() {
    document.addEventListener("scroll", this.scrollEventListener);
    let lastPathNameElement = window.location.pathname.split("/")[window.location.pathname.split("/").length-1]
    if(lastPathNameElement === ""){
      this.onGetOther("home")
    }else{
      this.onGetOther(lastPathNameElement)
    }

    this.setState({lastPathNameElement: lastPathNameElement})

  }

  componentDidUpdate(prewProps, prewState) {
    if(this.state.otherOffsetHeight !== document.getElementById('otherpageNavbar-id')?.firstChild.offsetHeight){
      this.setState({otherOffsetHeight: document.getElementById('otherpageNavbar-id')?.firstChild.offsetHeight})
      this.props.context.onOtherOffsetHeight(document.getElementById('otherpageNavbar-id').firstChild.offsetHeight);
    }
    if(this.state.lastPathNameElement !== window.location.pathname.split("/")[window.location.pathname.split("/").length-1]){
      //document.addEventListener("scroll", this.scrollEventListener);
      this.listInnerRefOverview.current.scrollIntoView({
     });
     let lastPathNameElement = window.location.pathname.split("/")[window.location.pathname.split("/").length-1]
     if(lastPathNameElement === ""){
       this.onGetOther("home")
     }else{
       this.onGetOther(lastPathNameElement)
     }
     this.setState({lastPathNameElement: lastPathNameElement === "" ? "home" : lastPathNameElement})
    }
  }

	componentWillUnmount() {
		document.removeEventListener("scroll", this.scrollEventListener);
	}


  onRefOverview = (tab) => {
    this.setState({activeItem: tab})
		this.listInnerRefOverview.current.scrollIntoView({
 	 });
	}

	onRefTextsection = (tab) => {
    this.setState({activeItem: tab})
		this.listInnerRefTextsection.current.scrollIntoView({
	 });
	}

	onRefLinksection = (tab) => {
    this.setState({activeItem: tab})
		this.listInnerRefLinksection.current.scrollIntoView({
	 });
	}

  onRefDocumentsection = (tab) => {
    this.setState({activeItem: tab})
		this.listInnerRefDocumentsection.current.scrollIntoView({
	 });
	}

  onRefVideosection = (tab) => {
    this.setState({activeItem: tab})
		this.listInnerRefVideosection.current.scrollIntoView({
	 });
	}

  onRefRelatedProducts = (tab) => {
    this.setState({activeItem: tab})
		this.listInnerRefRelatedProducts.current.scrollIntoView({
	 });
	}

  scrollEventListener = (event) => {
		const navPadding = window.scrollY < 80 ? true : false;
    const paddingHeight = this.props.context.mainOffsetHeight +
                          this.props.context.submenuOffsetHeight +
                          this.props.context.linkOffsetHeight +
                          this.props.context.otherOffsetHeight + 30;
    let tabscroll = 0
    let text_section = document.getElementById('text-section-id');
    let link_section = document.getElementById('link-section-id');
    let document_section = document.getElementById('document-section-id');
    let video_section = document.getElementById('video-section-id');

    if(text_section!== null && window.scrollY > text_section.offsetTop - paddingHeight){
      tabscroll = this.state.navbaritems.findIndex(navbaritem => navbaritem.value === "Related text");
    }
    if(link_section!== null && window.scrollY > link_section.offsetTop - paddingHeight){
      tabscroll = this.state.navbaritems.findIndex(navbaritem => navbaritem.value === "Product range");
    }
    if(document_section!== null && window.scrollY > document_section.offsetTop - paddingHeight){
      tabscroll = this.state.navbaritems.findIndex(navbaritem => navbaritem.value === "Documents");
    }
    if(video_section!== null && window.scrollY > video_section.offsetTop - paddingHeight){
      tabscroll = this.state.navbaritems.findIndex(navbaritem => navbaritem.value === "Videos");
    }
		this.setState({ navbarPaddingTop: navPadding,  activeItem: tabscroll });
	}

  onGetOther = (shortLink) => {
    let self = this;
    this.props.context.interAxios.get('/otherpage/shortlink/' + shortLink , function(response) {
      let newNavbaritems = [{value: "Overview", funktion: self.onRefOverview}];
      if(response.data.sections!==undefined){
        if(response.data.sections.filter((k) => (k.sectionType === "TEXT")).length > 0){
          newNavbaritems.push({value: "Related text", funktion: self.onRefTextsection});
        }
        if(response.data.sections.filter((k) => (k.sectionType === "LINK")).length > 0){
          newNavbaritems.push({value: "Product range", funktion: self.onRefLinksection});
        }
        if(response.data.sections.filter((k) => (k.sectionType === "DOCUMENT")).length > 0){
          newNavbaritems.push({value: "Documents", funktion: self.onRefDocumentsection});
        }
        if(response.data.sections.filter((k) => (k.sectionType === "VIDEO")).length > 0){
          newNavbaritems.push({value: "Videos", funktion: self.onRefVideosection});
        }

      }
      let array = [];
      if(response.data.images !== undefined){
        array.push(response.data.images);
      }
      self.setState({other: response.data,
                     selectedFiles: array,
                     navbaritems: newNavbaritems});

    })
  }

  render(){
    let paddingHeight = 52;
    paddingHeight = this.props.context.submenuOffsetHeight !== undefined && this.props.context.submenuOffsetHeight !== 0 ? this.props.context.mainOffsetHeight +
                          this.props.context.submenuOffsetHeight +
                          this.props.context.linkOffsetHeight +
                          this.props.context.productOffsetHeight : 52 + this.props.context.linkOffsetHeight;
    let refMenu = this.state.navbaritems.map((k, n) => {
      return( <MDBNavbarItem key={n} active={this.state.activeItem === n}>
                <MDBNavbarLink to="#" onClick={() => k.funktion(n)} active={this.state.activeItem === n} className="d-flex align-items-center justify-content-center">
                  {k.value}
                </MDBNavbarLink>
              </MDBNavbarItem>
      )
    })
    return (
      <>
        {this.state.other && (
          <div className="productpageNavbar d-none d-lg-block" id="otherpageNavbar-id">
        <MDBNavbar light bgColor='light'
                   fixed='top'
                   expand='lg'
                   id="withoutScroll"
                   style={{top: this.props.context.submenuOffsetHeight !== undefined && this.props.context.submenuOffsetHeight !== 0 ? this.props.context.mainOffsetHeight + this.props.context.submenuOffsetHeight + this.props.context.linkOffsetHeight: 52}}>
          <MDBContainer >
            <MDBNavbarNav id="navbarrow" className='mr-auto mb-2 mb-lg-0'>
              {refMenu}
            </MDBNavbarNav>
          </MDBContainer>
        </MDBNavbar>
        </div>
        )}

        <MDBContainer>
          <div ref={this.listInnerRefOverview} style={{scrollMarginTop: paddingHeight}}/>
          <section className="overview">
            <div className="wrapper">
            {this.state.other.images !== undefined && this.state.other.images.length > 0 ?
              <ImagesComponent array={this.state.other.images}/>:false}
              <MDBRow className="m-0 pb-2">
              <span className="title-text p-0">{this.state.other.descriptionTitle}</span>
              </MDBRow>
              <div  id="bullet-point">
              <p className="detail-text" dangerouslySetInnerHTML={{__html: this.state.other.description}}/>
              </div>
            </div>
          </section>
        </MDBContainer>

        {this.state.other.sections !== undefined ? <>
        <MDBContainer>
          {this.state.other.sections.filter((k) => (k.sectionType === "TEXT")).length > 0 ? <>
          <div ref={this.listInnerRefTextsection} style={{scrollMarginTop: paddingHeight}} id="text-section-id"/>

          <section className="text-section">
            <TextSectionView array={this.state.other.sections.filter((k) => (k.sectionType === "TEXT"))}/>
          </section>
          </>:false}
        </MDBContainer>


        {this.state.other.sections.filter((k) => (k.sectionType === "LINK")).length > 0 ? <>
        <div ref={this.listInnerRefLinksection} style={{scrollMarginTop: paddingHeight}} id="link-section-id"/>
        <section className="link-section">
          <LinkSectionView array={this.state.other.sections.filter((k) => (k.sectionType === "LINK"))}/>
        </section></>:false}

        <MDBContainer>
          {this.state.other.sections.filter((k) => (k.sectionType === "DOCUMENT")).length > 0 ? <>
          <div ref={this.listInnerRefDocumentsection} style={{scrollMarginTop: paddingHeight}} id="document-section-id"/>
          <section className="document-section">
            <DocumentSectionView arrayDocuments={this.state.other.sections.filter((k) => (k.sectionType === "DOCUMENT"))
                  .map((k) => ({id: k.id, name: k.name, sectionType: k.sectionType, type: k.type !== null ? k.type : "Document", url: k.url, validForCountries: k.validForCountries, validForCountriesMultiplicity: k.validForCountriesMultiplicity, language: k.language, validFor: k.validFor}))
                  .concat(this.state.other.sections.filter((k) => (k.sectionType === "VIDEO"))
                  .map((k) => ({id: k.id, name: k.name, sectionType: "DOCUMENT", type: "Video", url: k.url, validForCountries: "All countries", validForCountriesMultiplicity: "Multiple"})))

                  }/>
          </section></>:false}
        </MDBContainer>
        {/*<>
        {this.state.other.sections.filter((k) => (k.sectionType === "VIDEO")).length > 0 ? <>
        <div ref={this.listInnerRefVideosection} style={{scrollMarginTop: paddingHeight}} id="video-section-id"/>
        <section className="video-section">
          <VideoSectionView array={this.state.other.sections.filter((k) => (k.sectionType === "VIDEO"))}/>
        </section></>:false}</>*/}

        {this.state.other.software?.length > 0 ? <>
        <div ref={this.listInnerRefVideosection} style={{scrollMarginTop: paddingHeight}} id="video-section-id"/>
        <section className="video-section">
          <SoftwareSectionView array={this.state.product.software}/>
        </section></>:false}

        </>:false}



      </>

    );
  }
}
export default function OtherPageFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const nodeRef = React.useRef(null)
    return <OtherPage context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef} />;
}
