import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import addSmall from "../../../assets/media/icons/Add Small.svg";
import arrowLeft from "../../../assets/media/icons/arrow-circle-left.png";
import edit from "../../../assets/media/icons/Edit.svg";
import trash from "../../../assets/media/icons/Trash-red.svg";
import danfossLogo from "../../../assets/media/logo/Danfoss logo.svg";
import Moment from 'moment';
import 'moment/locale/de';


import { MDBContainer,
         MDBRow,
         MDBCol,
         MDBBtn,
         MDBModal,
         MDBModalDialog,
         MDBModalContent,
         MDBModalHeader,
         MDBModalTitle,
         MDBModalBody,
         MDBModalFooter,
         MDBTable,
         MDBTableHead,
         MDBTableBody,
         MDBInput,
         MDBIcon,
         MDBSpinner,
         MDBSelect,
         MDBBadge,
         MDBCard
       } from 'mdb-react-ui-kit';



class FileUploadModal extends Component {

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {softwareList: []}

  }

  componentDidMount() {
    this.onGetAllSoftware();
  }

  componentDidUpdate(prewProps, prewState) {
  }

	componentWillUnmount() {
	}

  onGetAllSoftware = () => {
    let self = this;
    this.props.context.interAxios.get('/software', function(response) {

      self.setState({softwareList: response.data, isUpdate: false});
    })
  }

  onUpdateSoftware = (event) => {
    for(let i=0; i<this.state.softwareList.length; i++){
      if(this.state.softwareList[i].id === event){
        this.setState({nameUpdate: this.state.softwareList[i].name !== null ? this.state.softwareList[i].name : "",
                       descriptionUpdate: this.state.softwareList[i].description !== null ? this.state.softwareList[i].description : "",
                       softwareId: this.state.softwareList[i].id,
                       isUpdate: true, showUpload: false})
      }
    }
  }

  handleChoose = (event) => {
    this.inputRef.current.click();
  };

  handleZipFileChange = (event) => {
    const file = event.target.files[0];
    this.setState({fileName: file.name, selectedFile: file})
  };

  onFileNameChange = (event) => {
    this.setState({fileName: event.target.value});
  }

  onArticleChange = (editorState) => {
    this.setState({description: editorState});
  }

  onFileUpload = () => {
    this.setState({spinner: true});
    if(this.state.selectedFile!==undefined){
      let self = this;
      const formData = new FormData
      formData.append("file", this.state.selectedFile);
      formData.append("name", this.state.fileName);
      formData.append("description", this.state.description);
      this.props.context.interAxios.post('/software/file', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
        self.setState({spinner: false});
        self.onGetAllSoftware();
      });
    }else{
      alert("Choose a Zip file!");
    }

  }


  onUpdateNameChange = (event) => {
    this.setState({nameUpdate: event.target.value});
  }

  onUpdateArticleChange = (editorState) => {
    this.setState({descriptionUpdate: editorState});
  }

  onSaveUpdateSoftware = () => {
    let self = this;
    this.props.context.interAxios.put('/software/' + this.state.softwareId, {name: this.state.nameUpdate, description: this.state.descriptionUpdate}, function(response) {
      self.onGetAllSoftware();
      self.setState({nameUpdate: "", descriptionUpdate: "", isUpdate: false});
    })
  }

  onCloseUpdateSoftware = () => {
    this.setState({nameUpdate: "", descriptionUpdate: "", isUpdate: false});
  }

  onShowUpload = () => {
    this.setState({showUpload: true, nameUpdate: "", descriptionUpdate: "", isUpdate: false})
  }

  hideShowUpload = () => {
    this.setState({showUpload: false})
  }

  render(){
    let data = this.state.softwareList.map((k,n) => {
      return (
        <tr key={n}>
          <td>
            <div className='d-flex align-items-center'>
               <p className='fw-bold mb-1'>{k.name}</p>
            </div>
          </td>
          <td>
            <MDBBtn tag='a' color='none' className='mx-2' onClick={() => this.onUpdateSoftware(k.id)}>
              <img src={edit}/>
            </MDBBtn>
          </td>
        </tr>
      )
    })


    return (
      <div className="userservice-modal">
      <MDBModal show={this.props.showModal} tabIndex='-1' >
        <MDBModalDialog size='xl' scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="pt-3">
                <MDBCol>
                  <MDBRow center className="pb-3">
                    <img src={danfossLogo} style={{width: "70%"}}/>
                  </MDBRow>
                  <MDBRow center>
                    <span  className="service-title d-flex align-items-center justify-content-center">Sotware</span>
                  </MDBRow>
                </MDBCol>
              </MDBModalTitle>
              {this.state.spinner ?
                              <div className="spinner-position">
                                <MDBSpinner /><br/>
                                <span className="">Loading...</span>
                              </div>:false}
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow className="pb-4 px-3">
                <MDBCol md="3" center className="d-flex justify-content-start p-0">
                </MDBCol>
                <MDBCol md="9">
                  <span className="section-submneu-text">Update existing software</span>
                  <div className="d-flex align-items-center justify-content-center items-table pt-4">
                    <MDBTable align='middle' responsive small>
                    <caption>
                    <div className="d-flex align-items-center justify-content-center py-3">
                      <MDBBtn className="table-footer" onClick={this.onShowUpload} >
                        <img src={addSmall}/><span> Add new product</span>
                      </MDBBtn>
                    </div>
                    </caption>
                     <MDBTableHead>
                       <tr>
                         <th scope='col'>Software Name</th>
                         <th scope='col'>Actions</th>
                       </tr>
                     </MDBTableHead>
                     <MDBTableBody>
                       {data}
                     </MDBTableBody>
                    </MDBTable>
                  </div>
                  {this.state.isUpdate ?
                  <MDBCard className="fileupdate-card">
                    <MDBRow>
                        <span className="section-submneu-text">Software name</span>
                      <div style={{maxWidth: "553px"}}>
                      <MDBInput
                                    wrapperClass='mb-4'
                                    type='text'
                                    id='title'
                                    value={this.state.nameUpdate}
                                    onChange={this.onUpdateNameChange}/>
                      </div>
                    </MDBRow>
                    <MDBRow>
                      <span className="section-submneu-text">Description</span>
                      <div style={{width: "80%"}}>
                        <CKEditor
                          editor={ ClassicEditor }
                          data={this.state.descriptionUpdate}
                          config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
                          onChange={
                            ( event, editor ) => {
                              const data = editor.getData();
                              this.onUpdateArticleChange(data);
                            }
                          }
                        />
                      </div>
                    </MDBRow>
                    <MDBRow end className="m-0 pt-3">
                      <MDBBtn type="btn" className="cancel me-2" onClick={this.onCloseUpdateSoftware}><span>Cancel</span></MDBBtn>
                      <MDBBtn type="btn" className="save" onClick={this.onSaveUpdateSoftware}><span>Update software</span></MDBBtn>
                    </MDBRow>
                  </MDBCard>:false}
                </MDBCol>
              </MDBRow>
              {this.state.showUpload ?
              <MDBRow className="pb-4 pt-2 px-3">
                <MDBCol md="3" center className="d-flex justify-content-start p-0">
                </MDBCol>
                <MDBCol md="9">
                  <MDBCard className="fileupdate-card">
                    <input ref={this.inputRef} className="FileInput_input" type="file" accept="application/zip" onChange={this.handleZipFileChange}/>
                    <MDBRow center>
                      <MDBBtn type="btn" className="upload" onClick={this.handleChoose}><MDBIcon fas icon="upload" size="3x"/></MDBBtn>
                    </MDBRow>

                    <MDBRow center className="pt-4">
                      <span className="section-submneu-text d-flex justify-content-center">File name</span>
                      <div style={{width: "80%"}}>
                        <MDBInput label='File name' id='form1' type='fileName' value={this.state.fileName} onChange={this.onFileNameChange}/>
                      </div>
                    </MDBRow>
                    <MDBRow center className="pt-4">
                      <span className="section-submneu-text d-flex justify-content-center">Description</span>
                      <div style={{width: "80%"}}>
                        <CKEditor
                          editor={ ClassicEditor }
                          data={this.state.description}
                          config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
                          onChange={
                            ( event, editor ) => {
                              const data = editor.getData();
                              this.onArticleChange(data);
                            }
                          }
                        />
                      </div>
                    </MDBRow>
                    <MDBRow end className="pt-2">
                      <MDBBtn type="btn" className="cancel" onClick={this.hideShowUpload}>Cancel</MDBBtn>
                      <MDBBtn type="btn" className="save" onClick={this.onFileUpload}>Upload</MDBBtn>
                    </MDBRow>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
              :false}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBRow className="px-3">
                <MDBBtn type="btn" className="save" onClick={this.props.hideModal}>Close</MDBBtn>
              </MDBRow>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      </div>

    );
  }
}
export default function FileUploadModalFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const nodeRef = React.useRef(null)
    return <FileUploadModal context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef}
                     showModal = {props.showModal} hideModal = {props.hideModal}/>;
}
