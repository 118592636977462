import React, { Component, useContext, useState  } from "react";
import { Outlet } from 'react-router-dom';
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import AppContext from '../../AppContext';

import {  MDBIcon,
          MDBRow,
          MDBCol,
          MDBBtn
} from 'mdb-react-ui-kit';


class ProductsFromCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {productsList: []};
  };

  componentDidMount() {
    this.onGetProductsFromCategory(this.props.categoryId);
  }

  onGetProductsFromCategory = (id) => {
    let self = this;
    this.props.context.interAxios.get('/category/' + id + '/product-summary', function(response) {
      self.setState({productsList: response.data.filter(k => k.status)});
    })
  }

  onProductPage = (id) => {
    this.props.toggle("productsModal")
    /*const searchParams = new URLSearchParams();
    searchParams.append("categoryId", this.props.categoryId);
    searchParams.append("productId", id);
    this.props.navigate("/productpage?" + searchParams.toString());*/
  }



  render() {
    let array = [];
    array = this.state.productsList.map((k,n) => {
      const searchParams = new URLSearchParams();
      searchParams.append("categoryId", this.props.categoryId);
      searchParams.append("productId", k.id);
      return (
               <MDBRow className="m-0" key={n} >
                 <Link className="link-products" onClick={() => this.props.productSetBasicModal(false)} to={"/productpage?" + searchParams.toString()}>
                  <span key={n} >{k.title}</span>
                 </Link>
               </MDBRow>
             )
    })
      return (<>
        {array}
        </>
      )
  }
}
export default function ProductsFromCategoryFunc(props) {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    let location = useLocation();
    return <ProductsFromCategory context={context} navigate={navigate} categoryId={props.categoryId} productSetBasicModal={props.productSetBasicModal}/>;
}
