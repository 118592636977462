import React, { Component } from "react";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdb-react-ui-kit';
import ImagecropHome from './Imagecrop/ImagecropHome'
import ModalTemplate from "../ModalTemplate";

class CreateImageHome extends Component {

  constructor(props) {
    super(props);
    this.state = {
                  };
  };

  componentDidMount() {
  };

  componentWillUnmount() {

  }

internalHandleSave = () => {
  this.props.onCropComplete(this.state.croppedImage);
  this.props.hideModal();
}

onMakeCroppedImage = (croppedImage) => {
  this.setState({croppedImage : croppedImage})

}



  render() {
    let btnForm = [  {classname: "cancel" , onclick: this.props.hideModal , text: "Cancel"},
                {classname: "save" , onclick:  this.state.croppedImage !== undefined ? this.internalHandleSave : this.props.hideModal , text: "Save"}
             ]
    return (
      <ModalTemplate
  				{...this.props}
  				internalHandleSave={this.internalHandleSave}
          btnForm={btnForm}
          modalId={""}
          imageWidth = {this.props.imageWidth}
  			>
         <ImagecropHome
            onCropComplete={this.props.onCropComplete} onMakeCroppedImage={this.onMakeCroppedImage} hideModal={this.props.hideModal}
            imageWidth = {this.props.imageWidth} imageHeight = {this.props.imageHeight}
            />
      </ModalTemplate>
    );
  }
}

export default CreateImageHome;
