import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBContainer
} from 'mdb-react-ui-kit';

class VideoSectionView extends Component {

  constructor(props) {
    super(props);

  }



	render() {
    let textArray = this.props.array.map((k,n) => {
      return(<div key={n}>
        <MDBContainer>
          <MDBRow className="m-0 py-3">
          <span className="title-text p-0">{k.name}</span>
          </MDBRow>
        <div className="link-frame d-flex align-items-center justify-content-center">
        <MDBRow>
          <div className="d-none d-lg-block d-flex align-items-center justify-content-center py-1 ">
            <iframe width="560" height="315" src={k.url} allowFullScreen autoPlay className="photos-content"></iframe>
         </div>
         <div className="d-block d-lg-none d-flex align-items-center justify-content-center py-1 ">
           <iframe src={k.url} allowFullScreen autoPlay className="photos-content"></iframe>
        </div>
        </MDBRow>

        </div>
        </MDBContainer>
        </div>

      )
    })
    return  (
        <>
        <MDBContainer>
          <MDBRow className="m-0 py-3">
          <span className="title-text p-0">Videos</span>
          </MDBRow>
        </MDBContainer>


              {textArray}

        </>
  );
  }
}
export default VideoSectionView;
