import React, { Component, useContext, useEffect, useState  } from "react";
import { Outlet } from 'react-router-dom';
import { useNavigate, Navigate, Link, useLocation } from 'react-router-dom';
import AppContext from '../../AppContext'

import AdminNavbar from './navbar/AdminNavbar'

import NotMobileDevicePage from '../NotMobileDevicePage.js'

import styles from '../../assets/scss/modules/adminpage.module.scss';

import {MDBIcon, MDBContainer, MDBRow, MDBCol
} from 'mdb-react-ui-kit';




class AdminLandingPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
								  proba : "N/A",
									navbarPaddingTop: true,
									};
  };

    render() {
        return(
          <div className={styles.bodywrapper}>
          <MDBContainer className="adminLanding-container">
          <MDBRow style={{width: "100%"}}>
            <MDBCol lg="auto" sm="3" md="3" className="p-0">
            <AdminNavbar/>
            </MDBCol>
            <MDBCol sm="9" md="9">
              <div className='d-flex align-items-center justify-content-center'>
                <Outlet/>
              </div>
            </MDBCol>
          </MDBRow>
          </MDBContainer>
          </div>
        )
    }
}

export default function LandingPageFunc() {
  const navigate = useNavigate();
  if(window.matchMedia('(max-device-width: 768px)').matches){
    return <NotMobileDevicePage/>;
  }else{
    const context = useContext(AppContext);
    const [showBasic, setShowBasic] = useState(false);
    const [showBasicSubmenu, setShowBasicSubmenu] = useState(false);
    useEffect(() => {
      if (!context.isLoggedIn()) {
        navigate('/admin/login');
      }
    });
    return <AdminLandingPage  context={context}
                              showBasic={showBasic}
                              setShowBasic={setShowBasic}
                              showBasicSubmenu={showBasicSubmenu}
                              setShowBasicSubmenu={setShowBasicSubmenu}/>;
  }

}
