import React, { Component, useContext, useState  } from "react";
import { Outlet } from 'react-router-dom';
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import {
  arrayMove
} from '@dnd-kit/sortable';
import AppContext from '../../../AppContext';
import CardTemplate from '../cards/CardTemplate.js';
import AddNewCard from '../cards/AddNewCard.js';
import DeleteModal from '../modals/categories/DeleteModal.js';
import OtherPageDragAndDrop from '../components/OtherPageDragAndDrop.js'
import trash from "../../../assets/media/icons/Trash.svg";
import setting from "../../../assets/media/icons/Setting.svg";
import edit from "../../../assets/media/icons/Edit.svg";
import addSmall from "../../../assets/media/icons/Add Small.svg";

import {  MDBIcon,
          MDBRow,
          MDBCol,
          MDBBtn
} from 'mdb-react-ui-kit';



var params = window.location.pathname.split("/");
const urlName = params[params.length - 1] !== "" && params[params.length - 1] !== "admin" ?
    params[params.length - 1].charAt(0).toUpperCase() + params[params.length - 1].slice(1) : "Products";

class OtherPages extends Component {

  constructor(props) {
    super(props);
    this.state = {otherpagesList: [],
                  homePage:{}
									};
  };

  componentDidMount() {
    this.onGetAllOtherPages();
    this.onGetHomePage();
  }

  onGetAllOtherPages = () => {
    let self = this;
    this.props.context.interAxios.get('/otherpage', function(response) {
      self.setState({otherpagesList: response.data.filter(k => k.name!=="Home")});
    })
  }

  onGetHomePage = () => {
    let self = this;
    this.props.context.interAxios.get('/otherpage/' + 116, function(response) {
      self.setState({homePage: response.data});
    })
  }

  showEditOtherPage = (id) => {
    const searchParams = new URLSearchParams();
    searchParams.append("otherPageId", id);
    this.props.navigate(id === this.state.homePage.id ? "/admin/otherpageshomepage?" + searchParams.toString() : "/admin/otherpagesdetail?" + searchParams.toString());
  };

  showDeleteOtherPage = (id, otherPagetName) => {
    let deleteText = <span className="deletemodal-text">Do you want to delete your "
                      <b>{otherPagetName}</b>
                      " page?</span>;
    this.setState({showModal: true, otherPageId: id, otherPagetName: otherPagetName, status: "Delete page", text: deleteText})
  };

  onDeleteOtherPage = (id) => {
    let self = this;
    this.props.context.interAxios.delete('/otherpage/' + id , function(response) {
      self.onGetAllOtherPages();
    })
  }

  hideDeleteOtherPage = () => {
    this.setState({showModal: false, otherPageId: "", otherPagetName: ""})
  }

  handleDragEnd = (event) => {
   const {active, over} = event;
   let array = this.state.otherpagesList.map((k) => (k.id));
   if (active.id !== over.id) {
     const oldIndex = array.indexOf(active.id);
     const newIndex = array.indexOf(over.id);
     let reorderedOtherpagesShortlinkList = arrayMove(this.state.otherpagesList, oldIndex, newIndex).map((k) => (k.shortlink))
     let self = this;
     this.props.context.interAxios.put('/otherpage/order', reorderedOtherpagesShortlinkList, function(response) {
       self.setState(
         {otherpagesList:  response.data.filter(k => k.name!=="Home")}
       );
     })
     /*this.setState(
       {otherpagesList: arrayMove(this.state.otherpagesList, oldIndex, newIndex)}
     );*/
   }
 }



  render() {
      let dropDownLis = [
        { id: "1",
          url: setting,
          text: "Edit category",
          function: this.showEditOtherPage},
        { id: "2",
          url: trash,
          text: "Delete",
          function: this.showDeleteOtherPage}
      ];

      let otherpagesList = [];
      otherpagesList = this.state.otherpagesList.map(
        (k, n) => {
              return (
                <MDBCol lg="4" sm="6" key={k.id} >

                  <OtherPageDragAndDrop id={k.id}
                                        url={k.images[0]?.url}
                                        title={k.name}
                                        dropDownLis={dropDownLis}/>

                </MDBCol>
              );
            }
      )
      return (
        <div style={{width: "100%"}}>
          <MDBRow className="pt-5 me-2 ms-2">
            <MDBCol center md="6">
            <h2 className="title-text">Other Pages List</h2>
            <span className="url-text">Other Pages</span>
            </MDBCol>
            <MDBCol center md="6" className="col-btn">
                <MDBBtn type="btn" onClick={() => this.showEditOtherPage("")} className="danfoss-btn admin">
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={addSmall}/><span> Add new page</span>
                  </div>
                </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow className="me-2 ms-2">
            <MDBCol lg="4" sm="6">
              <CardTemplate id={this.state.homePage.id}
                            url={this.state.homePage.images !== undefined ? this.state.homePage.images[0].url : null}
                            title={this.state.homePage.name}
                            dropDownLis={dropDownLis.filter(k => k.id!=="2")}/>
            </MDBCol>
            <OtherPageDragAndDrop otherpagesList={this.state.otherpagesList} dropDownLis={dropDownLis} handleDragEnd={this.handleDragEnd}/>
            <MDBCol lg="4" sm="6" className="add-col">
              <MDBBtn type="btn" onClick={() => this.showEditOtherPage("")}>
                <AddNewCard text={"Add new page"}/>
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow>
          </MDBRow>
          {this.state.showModal ?
          <DeleteModal hideModal={this.hideDeleteOtherPage}
                       showModal={this.state.showModal}
                       modalTyp={this.state.status}
                       productId={this.state.otherPageId}
                       onDeleteProduct={this.onDeleteOtherPage}
                       textend={this.state.text}/> : false}

        </div>
      )
  }
}

  export default function OtherPagesFunc(props) {
      const context = useContext(AppContext);
      const navigate = useNavigate();
      let location = useLocation();
      return <OtherPages context={context} navigate={navigate}/>;
  }
