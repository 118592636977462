import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../AppContext';

import Moment from 'moment';
import 'moment/locale/de';

import ViewCard from '../components/Cards/ViewCard.js'

import addSmall from "../assets/media/icons/Add Small.svg";
import arrowLeft from "../assets/media/icons/arrow-circle-left.png";
import edit from "../assets/media/icons/Edit.svg";
import trash from "../assets/media/icons/Trash-red.svg";
import addNew from "../assets/media/icons/Add New.svg";
import question from "../assets/media/logo/Danfoss-logo.png";

import { MDBContainer,
         MDBRow,
         MDBCol
       } from 'mdb-react-ui-kit';


class CategoryListPage extends Component {

  constructor(props) {
    super(props);

    this.state = {categoriesLis: []};

  }

  componentDidMount() {
    this.onGetAllCategory();
  }

  onGetAllCategory = () => {
    let self = this;
    this.props.context.interAxios.get('/category', function(response) {
      self.setState({categoriesLis: response.data});
    })
  }

  truncateString = (str, num) => {
    if(str !== null){
      if (str.length <= num) {
        return str
      }
          return str.slice(0, num) + '...'
    }
  }
  /*<MDBCol md="4" key={n} className="p-0 d-flex justify-content-center">
    <div className="viewCard-link">
      <Link to={urlText + k.id}>
        <ViewCard url={k.image !== null ? k.image.url : ""} title={k.title} description={k.description}/>
      </Link>
    </div>
  </MDBCol>*/
  render(){
    let urlText= "/categorypage?categoryId=";
    return (
      <div style={{width: "100%", marginTop: "10px"}} className="px-2">
      <MDBContainer>
        <MDBRow className="m-0">
          {this.state.categoriesLis.map((k, n) => {
            return(
              <MDBCol md="12" key={n} className="p-0 d-flex justify-content-center">
              <Link to={urlText + k.id} style={{width: "100%"}}>
              <div className="pb-3">
              <MDBRow className="m-0 pb-3" style={{borderBottom: "1px solid #D8D8D9"}}>
                <MDBCol md="2" className="p-0">
                  {k.image === null ?
                  <div className="quetion-card-products-search d-flex align-items-center justify-content-center">
                      <img src={question}/><br/>
                  </div>:
                  <img
                    src={k.image.url}
                    className=' img-thumbnail img-fluid hover-shadow'
                    alt=''
                  />}
                </MDBCol>
                <MDBCol md="10" className="p-0">
                  <MDBRow className="m-0 pb-2">
                    <span className="title-text">{k.title}</span>
                  </MDBRow>
                  <MDBRow className="m-0">
                    <span className="detail-text-search" dangerouslySetInnerHTML={{__html: this.truncateString(k.description, 200)}} />
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              </div>
              </Link>
              </MDBCol>
            )
          })}
        </MDBRow>
      </MDBContainer>
      </div>


    );
  }
}
export default function CategoryListPageFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const nodeRef = React.useRef(null)
    return <CategoryListPage context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef}/>;
}
