import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import CardTemplate from '../cards/CardTemplate.js';

import addSmall from "../../../assets/media/icons/Add Small.svg";
import arrowLeft from "../../../assets/media/icons/arrow-circle-left.png";
import edit from "../../../assets/media/icons/Edit.svg";
import trash from "../../../assets/media/icons/Trash-red.svg";
import addNew from "../../../assets/media/icons/Add New.svg";

import { MDBRow,
         MDBCol,
         MDBBtn
       } from 'mdb-react-ui-kit';

export function SortableCardTemplate(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
<MDBCol lg="4" sm="6" ref={setNodeRef} style={style} {...attributes} {...listeners} >
      <CardTemplate id={props.id} url={props.url} title={props.title} dropDownLis={props.dropDownLis}/>
    </MDBCol>
  );

}

export default SortableCardTemplate
