import React, { Component, useContext, useState  } from "react";
import AppContext from '../../../AppContext';
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import trash from "../../../assets/media/icons/Trash-red.svg";
import info from "../../../assets/media/icons/Info.svg";
import {
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBTooltip,
  MDBSelect
} from 'mdb-react-ui-kit';
class DocumentSection extends Component {

  constructor(props) {
    super(props);

    this.state = {document: this.props.url !== null ? this.props.url : "",
                  title: this.props.name !== null ? this.props.name : "",
                  type: this.props.type !== null ? this.props.type : "",
                  language: this.props.language !== null ? this.props.language : "",
                  validForCountries: this.props.validForCountries !== null ? this.props.validForCountries : "",
                  documentList: [],
                  data: []};
  }

  componentDidMount() {
    this.onGetAllDocuments();
  }

  onGetAllDocuments = () => {
    let self = this;
    this.props.context.interAxios.get('/document', function(response) {
      let data = [];
      if(response.data !== undefined){
        data = response.data.map(k => ({text: k.name, value: k.id}));
      }
      let updateData = [{text: "Select", value: -1, defaultSelected: true}]
      let finalUpdateData = updateData.concat(data);
      self.setState({documentList: response.data, data: finalUpdateData});
    })
  }




  onTitleChange = (event) => {
    this.setState({title: event.target.value});
    this.props.addNameToSection(this.props.index, event.target.value)
	}

  onDocumentChange = (event) => {
    this.setState({document: event.target.value});
    this.props.addUrlToSection(this.props.index, event.target.value)
	}

  onLanguageChange = (event) => {
    this.setState({language: event.target.value});
    this.props.addLanguageToSection(this.props.index, event.target.value)
  }
  onTypeChange = (event) => {
    this.setState({type: event.target.value});
    this.props.addTypeToSection(this.props.index, event.target.value)
	}
  onValidForCountriesChange = (event) => {
    this.setState({validForCountries: event.target.value});
    this.props.addValidForCountriesToSection(this.props.index, event.target.value)
	}

  onDocumentFromListChange = (event) => {
    let title = this.state.documentList.filter(k => k.id === event.value)[0].name;
    let name = this.state.documentList.filter(k => k.id === event.value)[0].url
    this.setState({title: title,
                   document: name})
    this.props.addNameToSection(this.props.index, title)
    this.props.addUrlToSection(this.props.index, name)
  }

	render() {
    return this.props.trashBin !== true ? (
        <MDBRow className='py-3'>
            <span className="add-title-text">Document section
              <MDBBtn type="btn" className="deletefunction-btn" onClick={() => this.props.onDeleteSection(this.props.index)}><img src={trash}/></MDBBtn>
            </span>
            <span className="section-submneu-text">Select existing document</span>
            <div style={{maxWidth: "300px"}} className="py-3">
              <MDBSelect
                id="software_section"
                data={this.state.data}
                label='Example label'
                onValueChange={(e) => this.onDocumentFromListChange(e)}
              />
            </div>
            <span className="section-submneu-text">Document section type</span>
          <div style={{maxWidth: "553px"}}>
          <MDBInput
                        wrapperClass='mb-4'
                        type='text'
                        id='type'
                        value={this.state.type}
                        onChange={this.onTypeChange}/>
          </div>
          <span className="section-submneu-text">Document section name</span>
        <div style={{maxWidth: "553px"}}>
        <MDBInput
                      wrapperClass='mb-4'
                      type='text'
                      id='title'
                      value={this.state.title}
                      onChange={this.onTitleChange}/>
        </div>
          <div className="info-tooltip d-flex align-items-center">
          <span className="section-submneu-text">Document section language</span>
          <MDBTooltip placement="right" title="Please enter only one language">
            <img src={info} style={{marginLeft: "10px"}}/>
          </MDBTooltip>
        </div>
      <div style={{maxWidth: "553px"}}>
      <MDBInput
                    wrapperClass='mb-4'
                    type='text'
                    id='language'
                    value={this.state.language}
                    onChange={this.onLanguageChange}/>
      </div>

      <div className="info-tooltip d-flex align-items-center">
        <span className="section-submneu-text">Document section valid for</span>
        <MDBTooltip placement="right" title="Enter the names of the available countries separated by commas, or use the phrase 'All countries'.">
          <img src={info} style={{marginLeft: "10px"}}/>
        </MDBTooltip>
      </div>
    <div style={{maxWidth: "553px"}}>
    <MDBInput
                  wrapperClass='mb-4'
                  type='text'
                  id='validForCountries'
                  value={this.state.validForCountries}
                  onChange={this.onValidForCountriesChange}/>
    </div>
            <span className="section-submneu-text">Document URL</span>
          <div style={{maxWidth: "553px"}}>
          <MDBInput
                        wrapperClass='mb-4'
                        type='text'
                        id='url'
                        value={this.state.document}
                        onChange={this.onDocumentChange}/>
          </div>
        </MDBRow>
  ) : <></>;
  }
}

export default function DocumentSectionFunc(props) {
  const context = useContext(AppContext);
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const nodeRef = React.useRef(null)
  return <DocumentSection context={context}
                          searchParams={searchParams}
                          navigate={navigate}
                          nodeRef={nodeRef}
                          onDeleteSection={props.onDeleteSection}
                          index={props.index}
                          addValidForCountriesToSection={props.addValidForCountriesToSection}
                          addTypeToSection={props.addTypeToSection}
                          addLanguageToSection={props.addLanguageToSection}
                          addUrlToSection={props.addUrlToSection}
                          addNameToSection={props.addNameToSection}
                          name={props.name}
                          type={props.type}
                          language={props.language}
                          validForCountries={props.validForCountries}
                          url={props.url}
                          trashBin={props.trashBin}
                          />;
}
