import React, { Component, useContext, useState  } from "react";
import { Outlet } from 'react-router-dom';
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../AppContext'
import NavbarComponent from '../components/NavbarComponent/NavbarComponent'
import NavbarComponentSubmenu from '../components/NavbarComponent/NavbarComponentSubmenu'
import FooterComponent from '../components/FooterComponent/FooterComponent'
import LinkComponent from '../components/NavbarComponent/LinkComponent'
import SideNavbar from '../components/NavbarComponent/SideNavbar.js'
import { Scrollbar } from "react-scrollbars-custom";

import styles from '../assets/scss/modules/landingpage.module.scss';

import {MDBIcon, MDBContainer, MDBScrollbar
} from 'mdb-react-ui-kit';


class LandingPage extends Component {

  constructor(props) {
    super(props);
    this.state = {showSideNavbar: false,
								  proba : "N/A",
									navbarPaddingTop: true,
                  firstLevel: "",
                  secondLevel: "",
                  thirdLevel: "",
                  mainOffsetHeight: document.getElementById('main-navbar-id') !== null ? document.getElementById('main-navbar-id').firstChild.offsetHeight : 0,
                  submenuOffsetHeight: document.getElementById('submenu-navbar-id') !== null ? document.getElementById('submenu-navbar-id').firstChild.offsetHeight : 0,
                  linkOffsetHeight: document.getElementById('link-navbar-id') !== null ? document.getElementById('link-navbar-id').firstChild.offsetHeight : 0
									};

  };

  componentDidMount() {
    let lastPathNameElement = window.location.pathname.split("/")[window.location.pathname.split("/").length-1]
    if(lastPathNameElement === ""){
      this.props.navigate("/home");
    }

		document.addEventListener("scroll", this.scrollEventListener);
    this.onGetAllOtherPages();
    this.onGetAllCategory();
	}

  componentDidUpdate(prewProps, prewState) {
    if(this.state.mainOffsetHeight !== document.getElementById('main-navbar-id')?.firstChild.offsetHeight){
      this.setState({mainOffsetHeight: document.getElementById('main-navbar-id')?.firstChild.offsetHeight})
      this.props.context.onMainOffsetHeight(document.getElementById('main-navbar-id').firstChild.offsetHeight);
    }
    if(this.state.submenuOffsetHeight !== document.getElementById('submenu-navbar-id')?.firstChild.offsetHeight){
      this.setState({submenuOffsetHeight: document.getElementById('submenu-navbar-id')?.firstChild.offsetHeight})
      this.props.context.onSubmenuOffsetHeight(document.getElementById('submenu-navbar-id').firstChild.offsetHeight);
    }

  }

	componentWillUnmount() {
		document.removeEventListener("scroll", this.scrollEventListener);
	}

  onGetAllOtherPages = () => {
    let self = this;
    this.props.context.interAxios.get('/otherpage', function(response) {
      self.setState({otherpagesList: response.data.filter(k => k.shortlink!=="home"),
                     homePage: response.data.filter(k => k.shortlink==="home")});
    })
  }

  onGetAllCategory = () => {
    let self = this;
    this.props.context.interAxios.get('/category', function(response) {
      self.setState({categorisLis: response.data});
    })
  }

  scrollEventListener = (event) => {
		const navPadding = window.scrollY < 80 ? true : false;
		this.setState({ navbarPaddingTop: navPadding });
	}





    render() {
        let url = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
        return(

          <div className={styles.bodywrapper} style={{paddingTop: this.props.context.mainOffsetHeight !== undefined ?
                        this.props.context.mainOffsetHeight +
                        this.props.context.submenuOffsetHeight +
                        this.props.context.linkOffsetHeight + 49 : 52 + this.props.context.linkOffsetHeight}}>

            <div className="main-navbar" id="main-navbar-id">
              <NavbarComponent navbarPaddingTop={this.state.navbarPaddingTop} onshowSideNavbar={this.props.setSidenavOpen}/>
            </div>
            <div className="submenu-navbar d-none d-lg-block" id="submenu-navbar-id">
              <NavbarComponentSubmenu otherpagesList={this.state.otherpagesList !== undefined ? this.state.otherpagesList : []}
                                      categorisLis={this.state.categorisLis !== undefined ? this.state.categorisLis : []}
                                      homePage={this.state.homePage !== undefined ? this.state.homePage : []}
                                      navbarPaddingTop={this.state.navbarPaddingTop}/>
            </div>

            <div className="side-navbar d-block d-lg-none">
              <SideNavbar otherpagesList={this.state.otherpagesList !== undefined ? this.state.otherpagesList : []}
                          categorisLis={this.state.categorisLis !== undefined ? this.state.categorisLis : []}
                          homePage={this.state.homePage !== undefined ? this.state.homePage : []}
                          hideSideNavbar={this.hideSideNavbar}
                          showSideNavbar={this.props.sidenavOpen}
                          onshowSideNavbar={this.props.setSidenavOpen}
                          navbarPaddingTop={this.state.navbarPaddingTop}/>
            </div>

            <div className="link-navbar" id="link-navbar-id" style={{display:
            url === "home" || url === "" ? "none" : "block"}}>
              <LinkComponent navbarPaddingTop={this.state.navbarPaddingTop}/>
            </div>
              <Outlet/>
            <div className="footer-component">
              <FooterComponent/>
            </div>
          </div>

        )
    }

}

export default function LandingPageFunc() {
    const context = useContext(AppContext);
    const [showBasic, setShowBasic] = useState(false);
    const [showBasicSubmenu, setShowBasicSubmenu] = useState(false);
    const [sidenavOpen, setSidenavOpen] = useState(true);
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    let location = useLocation();
    return <LandingPage context={context}
                        navigate={navigate}
                        searchParams={searchParams}
                        showBasic={showBasic}
                        setShowBasic={setShowBasic}
                        showBasicSubmenu={showBasicSubmenu}
                        setShowBasicSubmenu={setShowBasicSubmenu}
                        sidenavOpen={sidenavOpen}
                        setSidenavOpen={setSidenavOpen}
                        location={location}/>;
}
