import React, { Component, useContext } from "react";
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import AppContext from '../../AppContext'
import search from "../../assets/media/icons/search.svg";
import hamburger from "../../assets/media/icons/Hamburger.svg";
import logo from "../../assets/media/logo/logo.svg";
import engineering from "../../assets/media/icons/engineering.png";

import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';

class NavbarComponent extends Component {

	constructor(props) {
    super(props);
    this.state = {searchText: ""};
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  changeSearch = event => {
     this.setState({ [event.target.name]: event.target.value });
  }

  onKey = (ev) => {
		ev.preventDefault();
		this.onAllWithSearchText(this.state.searchText);
 	}

  onAllWithSearchText = (onAllWithSearchText) => {
    this.setState({searchText: ""})
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", onAllWithSearchText);
    this.props.navigate("/searchpage/searchText?" + searchParams.toString());
	}


	render() {
		return (
      <>
      <div className=" d-none d-lg-block fixed-main-navbar">
        <MDBNavbar id="withoutScroll" expand='lg' light bgColor='light'>
        <MDBContainer>
        <MDBNavbarBrand href='/home'><img
                        id="withoutScroll"
                        className="danfoss-logo"
                        src={logo}/>
          
              <img src={engineering} id="withoutScroll" className="engineering-img"/>
          
        </MDBNavbarBrand>
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
            <MDBCol md="6" className="d-flex align-items-center justify-content-center">
              <form id="withoutScroll" className='d-flex input-group'>
                <MDBBtn id="withoutScroll" type="button" className="search-button" onClick={() => this.onAllWithSearchText(this.state.searchText)}>
                  <img className="w-100" src={search}></img>
                </MDBBtn>
                <input
                                      onKeyPress={(ev) => {
                                          if (ev.key === "Enter") {
                                            this.onKey(ev);
                                          }
                                      }}
                                      type="search"
                                      className="form-control"
                                      id="formGroupExampleInput2"
                                      placeholder='Search'
                                      name="searchText"
                                      onChange={this.changeSearch}
                                      value={this.state.searchText}
                                    />

              </form>
            </MDBCol>
            <MDBCol md="6" className="d-flex align-items-center justify-content-end">
            <div className=" d-flex align-items-center justify-content-end landing-text" >
              <span className="">Technical Support and Service for Danfoss USA Hose Assembly Solutions Program</span>
            </div>
            </MDBCol>

          </MDBNavbarNav>
        </MDBContainer>
        </MDBNavbar>
      </div>
      <div className=" d-block d-lg-none  fixed-main-navbar">
        <MDBNavbar id="withoutScroll" expand='lg' light bgColor='light' >
        <MDBContainer>
          <MDBNavbarBrand href='/home' style={{width: "60%"}}><img
                          id="withoutScroll"
                          className="danfoss-logo"
                          src={logo}/>
            <MDBRow>
              <img src={engineering} id="withoutScroll" className="engineering-img"/>
            </MDBRow>
          </MDBNavbarBrand>
          {/*
            <MDBNavbarNav className='mr-auto'>
              <MDBNavbarItem  className="align-items-center justify-content-end">
                <MDBNavbarLink aria-current='page'>
                  <MDBBtn className="hamburger-btn" type="button" onClick={this.props.onshowSideNavbar}>
                    <img src={hamburger}/>
                  </MDBBtn>
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>*/}
        </MDBContainer>
        </MDBNavbar>
      </div>
      </>
		);
	}
}

export default function NavbarComponentFunc(props) {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    let location = useLocation();
    return <NavbarComponent context={context} navigate={navigate} navbarPaddingTop={props.navbarPaddingTop} onshowSideNavbar={props.onshowSideNavbar}/>;
}
