import React, { Component, useContext, useState  } from "react";
import AppContext from '../../../AppContext';
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const myregexp = /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.*([^\s]{11})/gm;
import trash from "../../../assets/media/icons/Trash-red.svg";

import {
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBSelect
} from 'mdb-react-ui-kit';

class VideoSection extends Component {

  constructor(props) {
    super(props);
    this.state = {videoLink: this.props.url !== undefined ?  this.props.url : "", video: this.props.url !== undefined ? this.props.url : "", title: this.props.name !== undefined ? this.props.name : "", data: []};
  }

  componentDidMount() {
    this.onGetAllVideos();
  }

  onGetAllVideos = () => {
    let self = this;
    this.props.context.interAxios.get('/video', function(response) {
      let data = [];
      if(response.data !== undefined){
        data = response.data.map(k => ({text: k.name, value: k.id}));
      }
      let updateData = [{text: "Select", value: -1, defaultSelected: true}]
      let finalUpdateData = updateData.concat(data);
      self.setState({videoList: response.data, data: finalUpdateData});
    })
  }

  onVideoChange = (event) => {
    this.setState({videoLink: ""});
    this.setState({video: event.target.value});
    let editorState = event.target.value;
		let videoLink = '';
		let text = '';
		if (editorState.match(myregexp)){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(editorState.match(myregexp), 'text/html');
			text =parsedHtml.documentElement.textContent;

	    if (text.startsWith("https://www.youtube.com/watch")) {
				let newVideoLink = text.replace("watch?v=", "embed/");
				let andIndex = newVideoLink.indexOf("&");
				if (andIndex != -1) {
					videoLink = newVideoLink.substring(0, andIndex);
				} else {
					videoLink = newVideoLink;
				}
			} else {
        if (text.startsWith("https://youtu.be/")) {
  				let newVideoLink = text.replace("youtu.be/", "www.youtube.com/embed/");
  				let andIndex = newVideoLink.indexOf("&");
  				if (andIndex != -1) {
  					videoLink = newVideoLink.substring(0, andIndex);
  				} else {
  					videoLink = newVideoLink;
  				}
  			} else {
            videoLink = text;
  			}
			}
			this.setState({videoLink: videoLink});
      this.props.addUrlToSection(this.props.index, videoLink)
		}else{
      this.setState({videoLink: event.target.value});
      this.props.addUrlToSection(this.props.index, event.target.value)
    }
	}

  onTitleChange = (event) => {
    this.setState({title: event.target.value});
    this.props.addNameToSection(this.props.index, event.target.value)
	}

  onVideoFromListChange = (event) => {
    let title = this.state.videoList.filter(k => k.id === event.value)[0].name;
    let name = this.state.videoList.filter(k => k.id === event.value)[0].url
    this.setState({title: title,
                   video: name,
                   videoLink: name})
    this.props.addNameToSection(this.props.index, title)
    this.props.addUrlToSection(this.props.index, name)
  }

 


	render() {
    return !this.props.trashBin ? (
        <div className='py-3'>
        <MDBRow>
            <span className="add-title-text">Video section
              <MDBBtn type="btn" className="deletefunction-btn" onClick={() => this.props.onDeleteSection(this.props.index)}><img src={trash}/></MDBBtn>
            </span>
            <span className="section-submneu-text">Select existing document</span>
            <div style={{maxWidth: "300px"}} className="py-3">
              <MDBSelect
                id="software_section"
                data={this.state.data}
                label='Example label'
                onValueChange={(e) => this.onVideoFromListChange(e)}
              />
            </div>
        </MDBRow>
        <MDBRow>
            <span className="section-submneu-text">Video section name</span>
          <div style={{maxWidth: "553px"}}>
          <MDBInput
                        wrapperClass='mb-4'
                        type='text'
                        id='title'
                        value={this.state.title}
                        onChange={this.onTitleChange}/>
          </div>
          </MDBRow>
          <MDBRow>
            <span className="section-submneu-text">Video URL</span>
          <div style={{maxWidth: "553px"}}>
          <MDBInput
                        wrapperClass='mb-4'
                        type='text'
                        id='video'
                        value={this.state.video}
                        onChange={this.onVideoChange}/>
          </div>
          </MDBRow>

          {this.state.videoLink !== "" ?
          <MDBRow>
          
          {this.state.videoLink.includes("/api/v3/storage/videos") ? 
            <div style={{maxWidth: "553px"}}>
              <div className="d-flex align-items-center justify-content-center py-3 video-background "  style={{maxWidth: "553px"}}>
              <video width="480" height="270" controls loop autoPlay muted key={this.state.videoLink}>
                  <source src={this.state.videoLink} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                </div>
            </div>
            :
            <div style={{maxWidth: "553px"}}>
              <div className="d-flex align-items-center justify-content-center py-3 video-background "  style={{maxWidth: "553px"}}>
                <iframe src={this.state.videoLink} allowFullScreen autoPlay className="photos-content"></iframe>
              </div>
            </div>
          }
         
          </MDBRow>:false}
        </div>
  ) : <></>;
  }
}

export default function VideoSectionFunc(props) {
  const context = useContext(AppContext);
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const nodeRef = React.useRef(null)
  return <VideoSection context={context}
                          searchParams={searchParams}
                          navigate={navigate}
                          nodeRef={nodeRef}
                          onDeleteSection={props.onDeleteSection}
                          index={props.index}
                          addUrlToSection={props.addUrlToSection}
                          addNameToSection={props.addNameToSection}
                          name={props.name}
                          url={props.url}
                          />;
}
