import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';

import AddNewCard from '../cards/AddNewCard.js';
import {SortableCardTemplate} from './SortableCardTemplate';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';

import {  MDBCol,
          MDBRow,
          MDBBtn,
          MDBFile
} from 'mdb-react-ui-kit';


class OtherPageDragAndDrop extends Component {

    constructor(props) {
      super(props);
      this.wrapper = React.createRef();
      this.state = {}
    }


    onSortEnd = ({oldIndex, newIndex}) => {
      this.props.handleDragEnd({oldIndex, newIndex});
    }





  render(){
    //var url = JSON.parse(JSON.stringify(this.props.selectedFiles));
    return (
      <DndContext
        sensors={this.props.sensors}
        collisionDetection={closestCenter}
        onDragEnd={this.props.handleDragEnd}
      >
        <SortableContext
          items={this.props.otherpagesList}
          strategy={verticalListSortingStrategy, horizontalListSortingStrategy}
        >
          {this.props.otherpagesList.map((k,n)=> <SortableCardTemplate key={k.id} id={k.id} url={k.images[0]?.url} title={k.name} onSortEnd={this.onSortEnd} dropDownLis={this.props.dropDownLis}/>)}

        </SortableContext>
      </DndContext>

    );
  }
}
export default function OtherPageDragAndDropFunc(props) {
    const context = useContext(AppContext);
    let location = useLocation();
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const sensors = useSensors(
      useSensor(MouseSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
          distance: 10,
        },
      }),
      useSensor(TouchSensor, {
        // Press delay of 250ms, with tolerance of 5px of movement
        activationConstraint: {
          delay: 250,
          tolerance: 5,
        },
      }),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );
    const nodeRef = React.useRef(null)
    return <OtherPageDragAndDrop context={context}
                        searchParams={searchParams}
                        navigate={navigate}
                        location={location}
                        isDeleteFunction={props.isDeleteFunction}
                        onFileChange={props.onFileChange}
                        handleShowAddImage={props.handleShowAddImage}
                        selectedFiles={props.selectedFiles}
                        onDeleteChange={props.onDeleteChange}
                        onSortEnd={props.onSortEnd}
                        handleDragEnd={props.handleDragEnd}
                        otherpagesList={props.otherpagesList}
                        dropDownLis={props.dropDownLis}
                        sensors={sensors}
                        nodeRef={nodeRef}/>;
}
