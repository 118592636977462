import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import {
  MDBRow,
  MDBInput,
  MDBBtn
} from 'mdb-react-ui-kit';

class TextSectionView extends Component {

  constructor(props) {
    super(props);

  }



	render() {
    let textArray = this.props.array.map((k,n) => {
      return(
        <div key={n}>
          <MDBRow className="m-0 py-3">
          <span className="title-text p-0">{k.name}</span>
          </MDBRow>
          <div  id="bullet-point">
          <MDBRow className="m-0">
            <p dangerouslySetInnerHTML={{__html: k.text}}/>
          </MDBRow>
          </div>
        </div>
      )
    })
    return  (
        <>
          {textArray}
        </>
  );
  }
}
export default TextSectionView;
