import React, { Component } from "react";
import { Navigate, Link } from 'react-router-dom';

import FooterContentEditModal from '../modals/FooterContentEditModal'
import DeleteModal from '../modals/DeleteModal.js';

import { MDBContainer,
         MDBDatatable,
         MDBBadge,
         MDBBtn,
         MDBIcon,
         MDBSwitch,
         MDBAlert,
         MDBRow,
         MDBCol
       } from 'mdb-react-ui-kit';

class AllFooterComponentTable extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    //this.onGetFooterById(this.props.footerId)
  }

  /*onGetFooterById = (id) => {
    let self = this;
    this.props.context.interAxios.get('/footer/' + id, function(response) {
      self.setState({
        footer: response.data
      });
    })
  }*/

  showFooterComponentEditModal = (id) => {
    this.setState({showFooterComponentEditModal: true, footerComponentId: id})
  }

  showNewFooterComponentModal = () => {
    this.setState({showFooterComponentEditModal: true, footerComponentId: undefined})
  }

  hideFooterComponentEditModal = (id) => {
    this.setState({showFooterComponentEditModal: false, footerComponentId: undefined})
  }

  showDeleteFooterComponentByIdModal = (id) => {
    this.setState({footerComponentId: id, showDeleteModal: true, status: "Delete footer component", modalText: "Do you want to delete this footer component?"})
  }

  hideDeleteFooterComponentByIdModal = () => {
    this.setState({footerComponentId: undefined, showDeleteModal: false})
  }


  render(){
    let self = this;
    let actionData = {
    columns: [
      { label: 'Component name', field: 'name' },
      { label: 'Actions', field: 'actions', sort: false}
    ],
    rows: this.props.footer.content.map((row, n) => {
      return {
        name: (  <div className="d-flex align-items-center justify-content-start">
                  <span className="ps-2">
                  {row.title}
                  </span>
                 </div>
        ),
        actions: (
          <div className="d-flex align-items-center justify-content-center">
            <MDBBtn className='fw-bold' color='link' size='sm' rippleColor='dark' onClick={() => this.showFooterComponentEditModal(row.id)}>
              <MDBIcon fas icon="pencil-alt" color="danger" />
            </MDBBtn>
            <MDBBtn className='fw-bold' color='link' size='sm' rippleColor='dark' onClick={() => this.showDeleteFooterComponentByIdModal(row.id)}>
              <MDBIcon fas icon="trash-alt" color="danger" />
            </MDBBtn>
          </div>
        ),
      };
    }),
  };

    return (
      <MDBContainer fluid className="p-0">
        <MDBRow className="py-3">
          <MDBCol md="6" className="d-flex align-items-center justify-content-start">
          <h1 className='m-0'>Footer Content List</h1>
          </MDBCol>
          <MDBCol md="6" className="d-flex align-items-center justify-content-end">
          <MDBBtn type="btn" className="danfoss-btn" onClick={this.showNewFooterComponentModal}>
            Add new Content
          </MDBBtn>
          </MDBCol>
        </MDBRow>
        <section>
          <div className='shadow-4 rounded-5 overflow-hidden items-table' style={{width: "50%"}}>
          <MDBDatatable hover
                        className="text-center"
                        pagination={false}
                        data={actionData}
                        format={(field) => {
                                if(field==='name') {
                                  return {width: "70%"}
                                }}
                              } />
          </div>
        </section>
        {this.state.showFooterComponentEditModal ?
          <FooterContentEditModal {...this.props} {...this.sate} {...this}  context={this.props.context} hideModal={this.hideFooterComponentEditModal} footerComponentId={this.state.footerComponentId}/>:false}
          {this.state.showDeleteModal ?
          <DeleteModal hideModal={this.hideDeleteFooterComponentByIdModal}
                       showModal={this.state.showDeleteModal}
                       modalTyp={this.state.status}
                       footerId={this.state.footerComponentId}
                       onDeleteFooter={this.props.onDeleteFooterComponent}
                       modalText={this.state.modalText}
                       context={this.props.context}
                      /> : false}
      </MDBContainer>
    );
  }

}
export default AllFooterComponentTable;
