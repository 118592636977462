import React, { Component, useContext } from 'react';
import AppContext from '../../AppContext';
import { MDBFooter,
         MDBContainer,
         MDBRow,
         MDBCol,
         MDBIcon,
         MDBBtn,
         MDBNavbar,
         MDBNavbarNav,
         MDBNavbarItem,
         MDBNavbarLink
       } from 'mdb-react-ui-kit';

import twitter from "../../assets/media/icons/twitter.svg";
import instagram from "../../assets/media/icons/instagram.svg";
import facebook from "../../assets/media/icons/facebook.svg";
import linkedin from "../../assets/media/icons/linkedin.svg";
import youtube from "../../assets/media/icons/youtube.svg";
import lomar_logo from "../../assets/media/logo/Lomar-Logo.png";

class Footercomponent extends Component {
  constructor(props) {
    super(props);
    this.state = {footerList: [], legalFooterList: []
                  };
  };

  componentDidMount() {
    this.onGetAllFooter();
  }

  onGetAllFooter = () => {
    let self = this;
    this.props.context.interAxios.get('/footer', function(response) {
      let legalFooterList = response.data.filter(k => k.content.length === 1 && k.content[0].title === "Legal Notices pattern");
      self.setState({legalFooterList: legalFooterList,
                     footerList: response.data.filter(x => !legalFooterList.includes(x))})
    })
  }


  render() {

  let aboutList = [];
  aboutList = this.state.footerList.map(
    (k, n) => {
          return (
            <MDBCol  key={n} md='auto' lg='auto' xl='auto' className='me-5 mb-2'>
             <h6 className='mb-3 footer-title-text'><a href={k.header.url} target="_blank">{k.header.title}</a></h6>
             <span key={n} className='footer-normal-text'>
               {k.content.map((l, m) => {return(
                 <div key={m} ><a href={l.url} target="_blank">
                   {l.title}
                 </a><br/></div>
               )})}
             </span>
            </MDBCol>
          );
        }
  )
  let legalList = [];
  legalList = this.state.legalFooterList.map(
    (k, n) => {
          return (
            <MDBNavbarItem key={n} >
              <MDBNavbarLink href={k.header.url} target="_blank">{k.header.title}</MDBNavbarLink>
            </MDBNavbarItem>
          );
        }
  )
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom' style={{ backgroundColor: '#D8D8D9' }}>
        <MDBContainer className='text-center text-md-start mt-1'>
          {/*<MDBRow>
            <MDBCol md='6' lg='6' xl='6' className='mx-auto'>
              <div className='me-5 d-none d-lg-block'>
                <p className="follow-our-global-ch">Follow our global channels </p>
              </div>

              <div className='d-flex align-items-center justify-content-start'>
                <a href='https://www.linkedin.com/company/danfoss?original_referer=' target="_blank" className='me-3 text-reset'>
                  <img className="social-icon" src={linkedin}></img>
                </a>
                <a href='https://twitter.com/danfoss' target="_blank" className='me-3 text-reset'>
                  <img className="social-icon" src={twitter}></img>
                </a>
                <a href='https://www.instagram.com/danfoss_group/' target="_blank" className='me-3 text-reset'>
                  <img className="social-icon" src={instagram}></img>
                </a>
                <a href='https://www.facebook.com/danfoss' target="_blank" className='me-3 text-reset'>
                  <img className="social-icon" src={facebook}></img>
                </a>
                <a href='https://www.youtube.com/c/danfoss' target="_blank" className='me-3 text-reset'>
                  <img className="social-icon" src={youtube}></img>
                </a>
              </div>
            </MDBCol>
            <MDBCol md='6' lg='6' xl='6' className='mx-auto text-end' center>
              <div className='d-none d-lg-block'>
                <MDBBtn className="danfoss-btn keep-me">Keep me updated</MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>*/}
          <MDBRow center className='my-0 pb-3'>
            <MDBCol md="1">
            </MDBCol>
            <MDBCol md="10"  className="d-flex align-items-center justify-content-center">
              <span className="footer-logo-text-normal">This site is provided by Lomar Machine and Tool Company,
              an authorized manufacturer, distribution and service center for
              Danfoss Global Hose Assembly Solutions Program servicing Danfoss®, Aeroquip®, Synflex® and Weatherhead® product lines.</span>
            </MDBCol>
            <MDBCol md="1">
            </MDBCol>
          </MDBRow>
          <MDBRow center className='my-0'>
            <MDBCol  className="d-flex align-items-center justify-content-center">
              <span className="footer-logo-text-title">1-888•AT•LOMAR (1•888•285•6627)</span>
            </MDBCol>
          </MDBRow>
          <MDBRow center className='my-0'>
            <MDBCol  className="d-flex align-items-center justify-content-center">
              <img src={lomar_logo} className="lomar-logo"/>
            </MDBCol>
          </MDBRow>
          <MDBRow center className='my-0'>
            <MDBCol  className="d-flex align-items-center justify-content-center">
              <span className="footer-logo-text-normal">Manufacturer | Service Center</span>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <section className='d-flex justify-content-center justify-content-lg-between' style={{ backgroundColor: '#484848' }}>
        <MDBContainer className='text-center text-md-start mt-3'>
          <MDBRow className='my-3'>
            <MDBCol md="12">
              <MDBRow>
                {aboutList}
              </MDBRow>
            </MDBCol>
            {/*<MDBCol  className="d-flex align-items-center justify-content-end" md="3">

              <img src={lomar_logo} className="lomar-logo"/>
            </MDBCol>*/}
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='p-4 footer-navbar'>
      <MDBNavbar expand='lg'>
        <MDBContainer className='text-center text-md-start'>
          <MDBNavbarNav className=''>
            {legalList}
            {/*<MDBNavbarItem>
              <MDBNavbarLink href='#'>Cookies</MDBNavbarLink>
            </MDBNavbarItem>*/}
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
      </div>
    </MDBFooter>
  );
  }
}

export default function FootercomponentFunc(props) {
    const context = useContext(AppContext);
    return <Footercomponent context={context}/>;
}
