import React, { Component, useContext, useState  } from "react";
import { Outlet } from 'react-router-dom';
import { Navigate, Link, useLocation } from 'react-router-dom';
import AppContext from '../../../AppContext';

import addNew from "../../../assets/media/icons/Add New.svg";


import {
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';


class AddNewCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
									};
  };

  render(){

    return (
      <div className="addNew-card pt-2">
        <div style={{height: "60%"}} className="d-flex align-items-end justify-content-center">
          <img src={addNew}/><br/>
        </div>
        <div className="d-flex align-items-end justify-content-center pt-2">
          <p>{this.props.text}</p>
        </div>
      </div>

    );
  }
}
export default function AddNewCardFunc(props) {
    const context = useContext(AppContext);
    return <AddNewCard context={context} url={props.url} dropDownLis={props.dropDownLis} title={props.title} text={props.text}/>;
}
