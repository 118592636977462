import React, { Component, useContext, useState  } from "react";
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import ModalTemplate from "./ModalTemplate";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSelect,
  MDBInput,
  MDBRow,
  MDBAlert
} from 'mdb-react-ui-kit';


class FooterEditModal extends Component {

	constructor(props) {
    super(props);
    this.state = {name: "", link: "",
                  footerId: this.props.footerId,
                  btnForm: [  {classname: "cancel" , onclick: this.props.hideFooterEditModal , text: "cancel"},
                              {classname: "save" , onclick: this.internalHandleSave , text: "Save"}]}
  }

  componentDidMount() {
    if(this.state.footerId !== undefined){
      this.onGetFooterById(this.state.footerId);
    }
  }

  onGetFooterById = (id) => {
    let self = this;
    this.props.context.interAxios.get('/footer/' + id, function(response) {
      self.setState({
        name: response.data.header.title,
        link: response.data.header.url
      });
    })
  }


  internalHandleSave = () => {
    if(this.state.name !== "" && this.state.link !== ""){
      if(this.state.footerId !== undefined){
        this.props.onUpdateFooter(this.state.footerId, this.state.name, this.state.link);
      }else{
        this.props.onCreateNewFooter(this.state.name, this.state.link);
      }

      this.props.hideFooterEditModal();
    }else{
      this.setState({alert: true, alertText:"Please fill in all fields"})
    }

  }

  onNameChange = (event) => {
    this.setState({name: event.target.value});
  }

  onLinkChange = (event) => {
    this.setState({link: event.target.value});
  }

  onDocumentFromListChange = (event) => {
    let link = this.props.documentList.filter(k => k.id === event.value)[0].url
    this.setState({link: link})
  }

	render() {
    let self = this;
    this.props.showModal
    return (
      <ModalTemplate
  				{...this.props}
  				internalHandleSave={this.internalHandleSave}
          btnForm={this.state.btnForm}
          modalId={""}
          showModal={this.props.showModal}
          hideModal={this.props.hideFooterEditModal}
  			>
          <MDBRow className="pb-3">
          <span className="section-submneu-text">Select existing document</span>
            <div className="py-3">
              <MDBSelect
                id="software_section"
                data={this.props.data}
                onValueChange={(e) => this.onDocumentFromListChange(e)}
              />
            </div>
        </MDBRow>
        <MDBRow className="pb-3">
          <span>Footer name<span style={{color: "red"}}>*</span></span>
          <MDBInput id='name'
                    type='text'
                    value= {this.state.name}
                    onChange={this.onNameChange} />
        </MDBRow>
        <MDBRow className="pb-3">
          <span>Link<span style={{color: "red"}}>*</span></span>
          <MDBInput id='link'
                    type='text'
                    value= {this.state.link}
                    onChange={this.onLinkChange} />
        </MDBRow>
        <MDBAlert
          show={this.state.alert}
          color='primary'
          autohide
          position='top-center'
          offset={50}
          delay={3000}
          appendToBody
          onClosed={() => { self.setState({alert: false, alertText: ""})}}
        >{this.state.alertText}</MDBAlert>
      </ModalTemplate>
  );
  }
}
export default FooterEditModal;
