import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Moment from 'moment';
import 'moment/locale/de';


import Homesection from '../homesection/Homesection.js';
import ViewHomePage from "./ViewHomePage";


import addSmall from "../../../assets/media/icons/Add Small.svg";
import arrowLeft from "../../../assets/media/icons/arrow-circle-left.png";
import edit from "../../../assets/media/icons/Edit.svg";
import trash from "../../../assets/media/icons/Trash-red.svg";
import addNew from "../../../assets/media/icons/Add New.svg";

import { MDBTable,
         MDBTableHead,
         MDBTableBody,
         MDBRow,
         MDBCol,
         MDBBtn,
         MDBCard,
         MDBCardBody,
         MDBCardTitle,
         MDBCardText,
         MDBInput,
         MDBTextArea,
       } from 'mdb-react-ui-kit';
import { MDBSortable, MDBSortableElement } from 'mdb-react-drag-and-drop';
class HomePageComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {name: "Add page name"};

  }

  componentDidMount() {
    this.onGetHomePage();
  }

  onGetHomePage = () => {
    let self = this;
    this.props.context.interAxios.get('/homepagecomponent', function(response) {
      self.setState({locationTop: response.data.filter(k => k.location === "TOP"),
                     locationMiddleLeft: response.data.filter(k => k.location === "MIDDLE_LEFT"),
                     locationMiddleRight: response.data.filter(k => k.location === "MIDDLE_RIGHT"),
                     locationBottomFirst: response.data.filter(k => k.location === "BOTTOM_FIRST"),
                     locationBottomSecond: response.data.filter(k => k.location === "BOTTOM_SECOND")});
    })

  }

  onSavePage = (location, title, description, url, linkText, selectedFile) => {
    let self = this;
    this.props.context.interAxios.post('/homepagecomponent',
      {location: location,
       title: title,
       description: description,
       url: url,
       linkText: linkText,
      }
      , function(response) {
        if(selectedFile !== undefined){
          self.handleSaveImage(response.data.id, selectedFile);
        }
      })
  }

  onSaveLocation = () => {
    let locationArray = [{location: "TOP", title: this.state.titleTop, description: this.state.descriptionTop, url :this.state.urlTop, linkText: this.state.linkTextTop, selectedFile: this.state.selectedFileTop},
                         {location: "MIDDLE_LEFT", title: this.state.titleMiddleLeft, description: this.state.descriptionMiddleLeft, url :this.state.urlMiddleLeft, linkText: this.state.linkTextMiddleLeft, selectedFile: this.state.selectedFileMiddleLeft},
                         {location: "MIDDLE_RIGHT", title: this.state.titleMiddleRight, description: this.state.descriptionMiddleRight, url :this.state.urlMiddleRight, linkText: this.state.linkTextMiddleRight, selectedFile: this.state.selectedFileMiddleRight},
                         {location: "BOTTOM_FIRST", title: this.state.titleBottomFirst, description: this.state.descriptionBottomFirst, url :this.state.urlBottomFirst, linkText: this.state.linkTextBottomFirst, selectedFile: this.state.selectedFileBottomFirst},
                         {location: "BOTTOM_SECOND", title: this.state.titleBottomSecond, description: this.state.descriptionBottomSecond, url :this.state.urlBottomSecond, linkText: this.state.linkTextBottomSecond, selectedFile: this.state.selectedFileBottomSecond}];

    for(let i=0; i<locationArray.length; i++){
      this.onSavePage(locationArray[i].location, locationArray[i].title, locationArray[i].description, locationArray[i].url, locationArray[i].linkText, locationArray[i].selectedFile)
    }
    this.backOtherPages();
  }

  handleSaveImage = (locationId, selectedFile) => {
    if(selectedFile.value !== undefined){
      let self = this;
      const formData = new FormData
      formData.append("file", selectedFile.value);
      this.props.context.interAxios.post('/image', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
        self.handleImageConnect(locationId, response.data.id);
      });
    }
  }

  handleImageConnect = (locationId, imageId) => {
    let self = this;
    this.props.context.interAxios.put('/homepagecomponent/' + locationId + '/connect-image/' + imageId, function(response) {
    })
  }



  backOtherPages = () => {
    const searchParams = new URLSearchParams();
    this.props.navigate("/admin/otherpages");
  }

  onSelectedFile = (location, selectedFile) => {
    if(location==="TOP"){
      this.setState({selectedFileTop: selectedFile, fotoUrlTop: selectedFile.fotoUrl})
    }
    if(location==="MIDDLE_LEFT"){
      this.setState({selectedFileMiddleLeft: selectedFile, fotoUrlMiddleLeft: selectedFile.fotoUrl})
    }
    if(location==="MIDDLE_RIGHT"){
      this.setState({selectedFileMiddleRight: selectedFile, fotoUrlMiddleRight: selectedFile.fotoUrl})
    }
    if(location==="BOTTOM_FIRST"){
      this.setState({selectedFileBottomFirst: selectedFile, fotoUrlBottomFirst: selectedFile.fotoUrl})
    }
    if(location==="BOTTOM_SECOND"){
      this.setState({selectedFileBottomSecond: selectedFile, fotoUrlBottomSecond: selectedFile.fotoUrl})
    }
  }

  onArticleChange = (location, description) => {
    if(location==="TOP"){
      this.setState({descriptionTop: description})
    }
    if(location==="MIDDLE_LEFT"){
      this.setState({descriptionMiddleLeft: description})
    }
    if(location==="MIDDLE_RIGHT"){
      this.setState({descriptionMiddleRight: description})
    }
    if(location==="BOTTOM_FIRST"){
      this.setState({descriptionBottomFirst: description})
    }
    if(location==="BOTTOM_SECOND"){
      this.setState({descriptionBottomSecond: description})
    }
  }

  onTitleChange = (location, title) => {
    if(location==="TOP"){
      this.setState({titleTop: title})
    }
    if(location==="MIDDLE_LEFT"){
      this.setState({titleMiddleLeft: title})
    }
    if(location==="MIDDLE_RIGHT"){
      this.setState({titleMiddleRight: title})
    }
    if(location==="BOTTOM_FIRST"){
      this.setState({titleBottomFirst: title})
    }
    if(location==="BOTTOM_SECOND"){
      this.setState({titleBottomSecond: title})
    }
	}

  onLinkTitleChange = (location, linkText) => {
    if(location==="TOP"){
      this.setState({linkTextTop: linkText})
    }
    if(location==="MIDDLE_LEFT"){
      this.setState({linkTextMiddleLeft: linkText})
    }
    if(location==="MIDDLE_RIGHT"){
      this.setState({linkTextMiddleRight: linkText})
    }
    if(location==="BOTTOM_SECOND"){
      this.setState({linkTextBottomSecond: linkText})
    }
    if(location==="BOTTOM_FIRST"){
      this.setState({linkTextBottomFirst: linkText})
    }
	}

  onUrlChange = (location, url) => {
    if(location==="TOP"){
      this.setState({urlTop: url})
    }
    if(location==="MIDDLE_LEFT"){
      this.setState({urlMiddleLeft: url})
    }
    if(location==="MIDDLE_RIGHT"){
      this.setState({urlMiddleRight: url})
    }
    if(location==="BOTTOM_FIRST"){
      this.setState({urlBottomFirst: url})
    }
    if(location==="BOTTOM_SECOND"){
      this.setState({urlBottomSecond: url})
    }
	}

  showPreview = () => {
      let homePage = [{location: "TOP", title: this.state.titleTop, description: this.state.descriptionTop, url :this.state.urlTop, linkText: this.state.linkTextTop,
                      image: {url: this.state.fotoUrlTop !== undefined ? this.state.fotoUrlTop : this.state.locationTop[0].image.url}},
                      {location: "MIDDLE_LEFT", title: this.state.titleMiddleLeft, description: this.state.descriptionMiddleLeft, url :this.state.urlMiddleLeft, linkText: this.state.linkTextMiddleLeft,
                      image: {url: this.state.fotoUrlMiddleLeft !== undefined ? this.state.fotoUrlMiddleLeft : this.state.locationMiddleLeft[0].image.url}},
                      {location: "MIDDLE_RIGHT", title: this.state.titleMiddleRight, description: this.state.descriptionMiddleRight, url :this.state.urlMiddleRight, linkText: this.state.linkTextMiddleRight,
                      image: {url: this.state.fotoUrlMiddleRight !== undefined ? this.state.fotoUrlMiddleRight : this.state.locationMiddleRight[0].image.url}},
                      {location: "BOTTOM_FIRST", title: this.state.titleBottomFirst, description: this.state.descriptionBottomFirst, url :this.state.urlBottomFirst, linkText: this.state.linkTextBottomFirst,
                      image: {url: this.state.fotoUrlBottomFirst !== undefined ? this.state.fotoUrlBottomFirst : this.state.locationBottomFirst[0].image.url}},
                      {location: "BOTTOM_SECOND", title: this.state.titleBottomSecond, description: this.state.descriptionBottomSecond, url :this.state.urlBottomSecond, linkText: this.state.linkTextBottomSecond,
                      image: {url: this.state.fotoUrlBottomSecond !== undefined ? this.state.fotoUrlBottomSecond : this.state.locationBottomSecond[0].image.url}}];
      this.setState({showPreview: true, homePage: homePage})
  }
  hidePreview = () => {
    this.setState({showPreview: false});
  }


  render(){
    let productName = this.props.searchParams.get("otherPageId") !== "" ? "Edit " + this.state.name : "Add new page";
    return (
      <div style={{width: "100%"}} className="px-2">
        <MDBRow className="pt-5">
          <MDBCol center md="2" sm="2" className="padding-col">
            <MDBBtn type="btn" onClick={this.backOtherPages} className="transparent-btn">
              <div className="d-flex align-items-center justify-content-center">
                <img src={arrowLeft}/>
              </div>
            </MDBBtn>
          </MDBCol>
          <MDBCol center className="margin-col">
          <h2 className="title-text">{productName}</h2>
          <span className="url-text">{"Other pages - " + productName}</span>
          </MDBCol>
          <MDBCol center md="auto" className="col-btn" style={{marginLeft: "73px"}}>
              <MDBBtn type="btn" onClick={this.onSaveLocation} className="newpage-btn">
                <div className="d-flex align-items-center justify-content-center">
                  <span>Save</span>
                </div>
              </MDBBtn>
          </MDBCol>
          <MDBCol center md="auto" className="col-btn">
              <MDBBtn type="btn" onClick={this.showPreview} className="newpage-btn">
                <div className="d-flex align-items-center justify-content-center">
                  <span>Preview</span>
                </div>
              </MDBBtn>
          </MDBCol>
        </MDBRow>
        {this.state.locationTop !== undefined ? <>
        <h2 className="title-text pt-5" style={{color:"red"}}>Top Section</h2>
        <Homesection location={this.state.locationTop} {...this}/></>:false}
        {this.state.locationMiddleLeft !== undefined ? <>
        <h2 className="title-text" style={{color:"red"}}>Middle left Section</h2>
        <Homesection location={this.state.locationMiddleLeft} {...this}/></>:false}
        {this.state.locationMiddleRight !== undefined ? <>
        <h2 className="title-text" style={{color:"red"}}>Middle right Section</h2>
        <Homesection location={this.state.locationMiddleRight} {...this}/></>:false}
        {this.state.locationBottomFirst !== undefined ? <>
        <h2 className="title-text" style={{color:"red"}}>Bottom Section first</h2>
        <Homesection location={this.state.locationBottomFirst} {...this}/></>:false}
        {this.state.locationBottomSecond !== undefined ? <>
        <h2 className="title-text" style={{color:"red"}}>Bottom Section second</h2>
        <Homesection location={this.state.locationBottomSecond} {...this}/></>:false}
        {this.state.showPreview ?
         <ViewHomePage
                   showModal= {this.state.showPreview}
                   homePage = {this.state.homePage}
                   hideModal = {this.hidePreview}
                   />: false}
      </div>
    );
  }
}
export default function HomePageComponentFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const nodeRef = React.useRef(null)
    return <HomePageComponent context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef}/>;
}
