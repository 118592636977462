import React, { Component, useContext, useState  } from "react";
import { MDBLightbox, MDBLightboxItem, MDBRow, MDBCol } from 'mdb-react-ui-kit';

class Lightbox extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

	render() {
    let boxitem = this.props.images.map((k,n) => {
      return(
        <MDBLightboxItem
          fullscreenSrc={k.url}
          className='w-100 shadow-1-strong rounded'
        />
      )
    })
    return (
      <MDBLightbox>
        {boxitem}
      </MDBLightbox>
    );
  }
}

export default Lightbox;
