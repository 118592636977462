import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';

import AddNewCard from '../cards/AddNewCard.js';
import {SortableItem} from './SortableItem';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';

import {  MDBCol,
          MDBRow,
          MDBBtn,
          MDBFile
} from 'mdb-react-ui-kit';


  class DragAndDrop extends Component {

    constructor(props) {
      super(props);
      this.wrapper = React.createRef();
      this.state = {}
    }


    onSortEnd = ({oldIndex, newIndex}) => {
      this.props.onSortEnd({oldIndex, newIndex});
    }

    remove = (index) => {
        this.props.onDeleteChange(index);
    }

  render(){
    var url = JSON.parse(JSON.stringify(this.props.selectedFiles));
    return (
      <div className="drag-area add-col align-items-center justify-content-start">
      <MDBRow className="m-0">
      <DndContext
        sensors={this.props.sensors}
        collisionDetection={closestCenter}
        onDragEnd={this.props.handleDragEnd}
      >
        <SortableContext
          items={url.map((file) => {file.id = file.url; return file;})}
          strategy={verticalListSortingStrategy, horizontalListSortingStrategy}
        >
          {url.map((k,n)=> <SortableItem key={k.id + 1000} id={k.url} url={k} ertek={n} onSortEnd={this.onSortEnd} onRemove={this.remove} isDeleteFunction={this.props.isDeleteFunction}/>)}
          <div className="md-form" className="p-0" style={{width: "161px"}}>
            <MDBFile multiple type="file" accept="image/*" name="sampleName" onChange={this.props.onFileChange}/>
            <MDBBtn type="btn" onClick={this.props.handleShowAddImage}>
            <AddNewCard text={"Add new image"} />
            </MDBBtn>
          </div>
        </SortableContext>
      </DndContext>
      </MDBRow>
      </div>

    );
  }
}
export default function DragAndDropFunc(props) {
    const context = useContext(AppContext);
    let location = useLocation();
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const sensors = useSensors(
      useSensor(MouseSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
          distance: 10,
        },
      }),
      useSensor(TouchSensor, {
        // Press delay of 250ms, with tolerance of 5px of movement
        activationConstraint: {
          delay: 250,
          tolerance: 5,
        },
      }),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );
    const nodeRef = React.useRef(null)
    return <DragAndDrop context={context}
                        searchParams={searchParams}
                        navigate={navigate}
                        location={location}
                        isDeleteFunction={props.isDeleteFunction}
                        onFileChange={props.onFileChange}
                        handleShowAddImage={props.handleShowAddImage}
                        selectedFiles={props.selectedFiles}
                        onDeleteChange={props.onDeleteChange}
                        onSortEnd={props.onSortEnd}
                        handleDragEnd={props.handleDragEnd}
                        sensors={sensors}
                        nodeRef={nodeRef}/>;
}
