import React, { Component } from "react";
import { Navigate, Link } from 'react-router-dom';

import FooterEditModal from '../modals/FooterEditModal'
import DeleteModal from '../modals/DeleteModal.js';

import { MDBContainer,
         MDBDatatable,
         MDBBadge,
         MDBBtn,
         MDBIcon,
         MDBSwitch,
         MDBAlert,
         MDBRow,
         MDBCol
       } from 'mdb-react-ui-kit';

class AllFooterTableLegalNotices extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
  }


  onShowFooterEditModal = (id) => {
    this.setState({showFooterEditModal: true, footerId: id})
  }

  onShowNewFooterModal = () => {
    this.setState({showFooterEditModal: true, footerId: undefined})
  }

  hideFooterEditModal = (id) => {
    this.setState({showFooterEditModal: false, footerId: undefined})
  }

  showDeleteFooterByIdModal = (id) => {
    this.setState({footerId: id, showDeleteModal: true, status: "Delete footer category", modalText: "Do you want to delete this footer category?"})
  }

  hideDeleteFooterByIdModal = () => {
    this.setState({footerId: undefined, showDeleteModal: false})
  }


  render(){
    let self = this;
    let actionData = {
    columns: [
      { label: 'Header name', field: 'name' },
      { label: 'Actions', field: 'actions', sort: false}
    ],
    rows: this.props.footerList.map((row, n) => {
      return {
        name: (  <div className="d-flex align-items-center justify-content-start">
                  <span className="ps-2">
                  {row.header.title}
                  </span>
                 </div>
        ),
        actions: (
          <div className="d-flex align-items-center justify-content-center">
            <MDBBtn className='fw-bold' color='link' size='sm' rippleColor='dark' onClick={() => this.onShowFooterEditModal(row.id)}>
              <MDBIcon fas icon="pencil-alt" color="danger"/>
            </MDBBtn>
            <MDBBtn className='fw-bold' color='link' size='sm' rippleColor='dark' onClick={() => this.showDeleteFooterByIdModal(row.id)}>
              <MDBIcon fas icon="trash-alt" color="danger"/>
            </MDBBtn>
          </div>
        ),
      };
    }),
  };

    return (
      <MDBContainer fluid className="p-0 mt-5" style={{borderTop: "1px solid"}}>
        <MDBRow className="py-3">
          <MDBCol md="6" className="d-flex align-items-center justify-content-start">
          <h1 className='m-0'>Legal Notices</h1>
          </MDBCol>
          <MDBCol md="6" className="d-flex align-items-center justify-content-end">
          <MDBBtn type="btn" className="danfoss-btn" onClick={this.onShowNewFooterModal}>
            Add new Element
          </MDBBtn>
          </MDBCol>
        </MDBRow>
        <section>
          <div className='shadow-4 rounded-5 overflow-hidden items-table' style={{width: "50%"}}>
          <MDBDatatable hover
                        className="text-center"
                        pagination={false}
                        data={actionData}
                        format={(field) => {
                                if(field==='name') {
                                  return {width: "70%"}
                                }}
                              } />
          </div>
        </section>
          {this.state.showFooterEditModal ?
          <FooterEditModal footerId={this.state.footerId} 
                           context={this.props.context} 
                           showModal={this.state.showFooterEditModal}
                           hideFooterEditModal={this.hideFooterEditModal}
                           onUpdateFooter={this.props.onUpdateFooter}
                           onCreateNewFooter={this.props.onCreateNewFooter}
                           documentList={this.props.documentList}
                           data={this.props.data}
                           />:false}
          {this.state.showDeleteModal ?
          <DeleteModal hideModal={this.hideDeleteFooterByIdModal}
                      showModal={this.state.showDeleteModal}
                      modalTyp={this.state.status}
                      footerId={this.state.footerId}
                      onDeleteFooter={this.props.onDeleteFooter}
                      modalText={this.state.modalText}
                      context={this.props.context}
                      
                      /> : false}
      </MDBContainer>
    );
  }

}
export default AllFooterTableLegalNotices;
