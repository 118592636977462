import React, { Component, useContext, useState  } from "react";
import { Outlet } from 'react-router-dom';
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../AppContext'

import { MDBContainer,
         MDBNavbar,
         MDBNavbarNav,
         MDBNavbarItem,
         MDBNavbarLink,
         MDBRow,
         MDBBtn
} from 'mdb-react-ui-kit';


class LinkComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {components: [],
                  sectionTypeList: [],
                  pathname: "",
                  titleText: "",
                  linkOffsetHeight: document.getElementById('linkcomponent')?.firstChild.offsetHeight
									};
  };

//this.props.context.
  componentDidMount() {
    let array = [];
    if(window.location.pathname.split("/")[1] === "" || window.location.pathname.split("/").length<1){
      array = [{value: "Home", link: "/home"}];
      this.setState({components: array, titleText: "", product: undefined, searchBackgroundColor: false})
    }
	}

  componentDidUpdate(prewProps, prewState) {
    if(this.state.linkOffsetHeight !== document.getElementById('linkcomponent')?.firstChild.offsetHeight){
      this.setState({linkOffsetHeight: document.getElementById('linkcomponent')?.firstChild.offsetHeight})
      this.props.context.onLinkOffsetHeight(document.getElementById('linkcomponent').firstChild.offsetHeight);
    }
    if(this.props.searchParams.get("productId") === null && this.props.searchParams.get("categoryId") === null && this.state.pathname !== window.location.pathname){
      let array = [];
      if(window.location.pathname.split("/")[1] === "page" && window.location.pathname.split("/")[2] === "home"){
        array = [{value: "Home", link: "/home"}];
        this.setState({components: array, titleText: "", product: undefined, searchBackgroundColor: false})
      }

      if(window.location.pathname.split("/")[1] === "page" && window.location.pathname.split("/")[2] !== "home"){
        let self = this;
        let shortlink = window.location.pathname.split("/")[2]
        this.props.context.interAxios.get('/otherpage/shortlink/' + shortlink, function(response) {
          self.setState({page: response.data});
          array = [{value: "Home", link: "/home"}];
          self.setState({components: array, titleText: response.data.name, product: undefined, searchBackgroundColor: false})
        })
      }

      if(window.location.pathname.split("/")[1] === "categorylistpage" ){
        array = [{value: "Home", link: "/home"}];
        this.setState({components: array, titleText: "Categories", product: undefined})
      }
      this.setState({pathname: window.location.pathname, searchParamProductId: null, searchParamCategoryId: null})
    }

    if(window.location.pathname.split("/")[1] === "searchpage" && this.state.searchText !== this.props.searchParams.get("searchText")){
      let array = [{value: "Home", link: "/home"}];
      let titletext= "Search results for '" + this.props.searchParams.get("searchText") + "'";
      this.setState({components: array, titleText: titletext, product: undefined, searchBackgroundColor: true, searchText:this.props.searchParams.get("searchText")})
    }

    if((this.props.searchParams.get("categoryId") !== this.state.searchParamCategoryId && this.props.searchParams.get("categoryId") !== null) ||
      (this.props.searchParams.get("categoryId") === this.state.searchParamCategoryId  &&  this.state.pathname !== window.location.pathname)){
      let array = [];
      if(window.location.pathname.split("/")[1] === "categorypage" ){
        let self = this;
        this.props.context.interAxios.get('/category/'  + this.props.searchParams.get("categoryId"), function(response) {
          self.setState({category: response.data});
          array = [{value: "Home", link: "/home"}, {value: "Categories", link: "/categorylistpage"}];
          self.setState({components: array, titleText: response.data.title, product: undefined, searchBackgroundColor: false})
        })
      }
      this.setState({pathname: window.location.pathname, searchParamProductId: null, searchParamCategoryId: this.props.searchParams.get("categoryId")})
    }

    if(this.props.searchParams.get("productId") !== this.state.searchParamProductId && this.props.searchParams.get("productId") !== null){
      let array = [];
      if(window.location.pathname.split("/")[1] === "productpage" ){
        let self = this;
        let linktext = "/categorypage?categoryId=" + this.props.searchParams.get("categoryId");
        this.props.context.interAxios.get('/category/'  + this.props.searchParams.get("categoryId"), function(response) {
          self.setState({category: response.data});
          array = [{value: "Home", link: "/home"}, {value: "Categories", link: "/categorylistpage"}, {value: response.data.title, link:linktext}];
          self.props.context.interAxios.get('/product/'  + self.props.searchParams.get("productId"), function(response) {
            self.setState({product: response.data});
            self.setState({components: array, titleText: response.data.title,
                           sectionTypeList: response.data.sections.map((k) => (k.sectionType))})
          })
          self.setState({components: array, searchBackgroundColor: false})
        })

      }
      this.setState({pathname: window.location.pathname, searchParamProductId: this.props.searchParams.get("productId")})
    }
	}

    render() {
        let navbaritems = this.state.components.map((k, n) => {
          return(
            <MDBNavbarItem key={n}>
              <Link className="" to={k.link}>
                {k.value}
              </Link>
            </MDBNavbarItem>
          )
        })
        return(
            <div id="linkcomponent">
            <MDBNavbar light bgColor={this.state.searchBackgroundColor ? "secondary" : "light"}
                             fixed='top'
                             id="withoutScroll"
                             style={{top: this.props.context.submenuOffsetHeight !== undefined && this.props.context.submenuOffsetHeight !== 0 ? this.props.context.mainOffsetHeight + this.props.context.submenuOffsetHeight: 52}}>
            <MDBContainer>

            <MDBNavbarNav id="navbarcolumn" className='mr-auto mb-2 mb-lg-0'>
              <MDBNavbarNav id="navbarrow" className='mr-auto mb-2 mb-lg-0'>
              {this.props.searchParams.get("productId") !== null || window.location.pathname.split("/")[1] === "page" ?
              <div className="where-to-buy-btn">
                <MDBBtn href="https://www.eatonpowersource.com/where-to-buy/" target="_blank">Where to buy</MDBBtn>
              </div>:false}
                {navbaritems}
              </MDBNavbarNav>
              <MDBNavbarNav id="navbarrow" className='mr-auto mb-2 mb-lg-0'>
                <MDBNavbarItem id="withoutScroll">
                  {this.state.titleText !== "" ?
                  <span className="title-text px-0" id="withoutScroll">{this.state.titleText}</span>: false}
                </MDBNavbarItem>
              </MDBNavbarNav>
              </MDBNavbarNav>
            </MDBContainer>
            </MDBNavbar>
            </div>
        )
    }

}

export default function LinkComponentFunc(props) {
    const context = useContext(AppContext);
    let location = useLocation();
    let [searchParams] = useSearchParams();
    return <LinkComponent context={context}
                          location={location}
                          searchParams={searchParams}
                          navbarPaddingTop={props.navbarPaddingTop}/>;
}
