import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';

import Moment from 'moment';
import 'moment/locale/de';

import AllFooterTable from '../TableComponent/AllFooterTable.js'
import AllFooterTableLegalNotices from '../TableComponent/AllFooterTableLegalNotices.js'
import AllFooterComponentTable from '../TableComponent/AllFooterComponentTable.js'

import addSmall from "../../../assets/media/icons/Add Small.svg";
import arrowLeft from "../../../assets/media/icons/arrow-circle-left.png";
import edit from "../../../assets/media/icons/Edit.svg";
import trash from "../../../assets/media/icons/Trash-red.svg";
import addNew from "../../../assets/media/icons/Add New.svg";

import { MDBContainer,
         MDBRow,
         MDBCol,
         MDBBtn,
         MDBAlert
       } from 'mdb-react-ui-kit';



class FooterComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {footerList: [], legalFooterList: []};

  }


  componentDidMount() {
    this.onGetAllFooter();
    this.onGetAllDocuments();
  }

  componentDidUpdate(prewProps, prewState) {
  }

	componentWillUnmount() {
	}

  onGetAllDocuments = () => {
    let self = this;
    this.props.context.interAxios.get('/document', function(response) {
      let data = [];
      if(response.data !== undefined){
        data = response.data.map(k => ({text: k.name, value: k.id}));
      }
      let updateData = [{text: "Select", value: -1, defaultSelected: true}]
      let finalUpdateData = updateData.concat(data);
      self.setState({documentList: response.data, data: finalUpdateData});
    })
  }

  onGetAllFooter = () => {
    let self = this;
    this.props.context.interAxios.get('/footer', function(response) {
      let legalFooterList = response.data.filter(k => k.content.length === 1 && k.content[0].title === "Legal Notices pattern");
      
      self.setState({legalFooterList: legalFooterList,
                     footerList: response.data.filter(x => !legalFooterList.includes(x))})
    })
  }

  onCreateNewFooter = (title, url) => {
    let self = this;
    this.props.context.interAxios.post('/footer',
              {
                header: {
                  title: title,
                  url: url
                },
                content: []

          },
    function(response) {
      self.onGetAllFooter();
    })
  }

  onCreateNewLegalFooter = (title, url) => {
    let self = this;
    this.props.context.interAxios.post('/footer',
              {
                header: {
                  title: title,
                  url: url
                },
                content: [{
                            title: "Legal Notices pattern",
                            url: "string"
                         }]

          },
    function(response) {
      self.onGetAllFooter();
    })
  }

  onUpdateFooter = (id, title, url) => {
    let self = this;
    this.props.context.interAxios.put('/footer/' + id,
              {
                header: {
                  title: title,
                  url: url
                },
                content: []
          },
    function(response) {
      self.onGetAllFooter();
    })
  }

  onUpdateLegalFooter = (id, title, url) => {
    let self = this;
    this.props.context.interAxios.put('/footer/' + id,
              {
                header: {
                  title: title,
                  url: url
                },
                content: [{
                  title: "Legal Notices pattern",
                  url: "string"
               }]
          },
    function(response) {
      self.onGetAllFooter();
    })
  }

  onDeleteFooter = (id) => {
    this.setState({alert: true, alertText:"You have successfully deleted the footer category"})
    let self = this;
    this.props.context.interAxios.delete('/footer/' + id, function(response) {
      self.onGetAllFooter();
    })
  }

  onGetFooterById = (id) => {
    this.setState({footer: {content: []}})
    let self = this;
    this.props.context.interAxios.get('/footer/' + id, function(response) {
      self.setState({
        footer: response.data
      });
    })
  }

  onCreateNewFooterComponent = (id, title, url) => {
    let self = this;
    this.props.context.interAxios.post('/footer/' + id + "/component",
              {
                  title: title,
                  url: url
              },
    function(response) {
      self.onGetAllFooter();
      self.onCloseThisFooterComponent();
    })
  }

  onUpdateFooterComponent = (id, title, url) => {
    let self = this;
    this.props.context.interAxios.put('/footercomponent/' + id ,
              {
                  title: title,
                  url: url
              },
    function(response) {
      self.onGetAllFooter();
      self.onCloseThisFooterComponent();
    })
  }

  onDeleteFooterComponent = (id) => {
    let self = this;
    this.props.context.interAxios.delete('/footercomponent/' + id , function(response) {
      self.onGetAllFooter();
      self.onCloseThisFooterComponent();
    })
  }

  onOpenThisFooterComponent = (id) => {
    let self = this;
    this.props.context.interAxios.get('/footer/' + id, function(response) {
      self.setState({
        footer: response.data,
        footerId: id,
        isOpenFooterComponentTable: true,
        activeGroupId: id
      });
    })
  }

  onCloseThisFooterComponent = () => {
    this.setState({footerId: null, isOpenFooterComponentTable: false, activeGroupId: null})
  }



  render(){
    let self = this;
    return (
      <>
      <MDBContainer className='pt-4 pt-lg-5'>
          <AllFooterTable {...this} {...this.state} {...this.props} context={this.props.context}
                          footerList={this.state.footerList} onCreateNewFooter={this.onCreateNewFooter} onUpdateFooter={this.onUpdateFooter}/>
          {this.state.isOpenFooterComponentTable ? <>
          <AllFooterComponentTable {...this} {...this.state} {...this.props} context={this.props.context} footer={this.state.footer}/>
          <div className="p-5" style={{height: "50px"}}/></>:false}
          <AllFooterTableLegalNotices {...this} {...this.state} {...this.props} context={this.props.context}
                          footerList={this.state.legalFooterList} onCreateNewFooter={this.onCreateNewLegalFooter} onUpdateFooter={this.onUpdateLegalFooter}/>
        </MDBContainer>
        <MDBAlert
          show={this.state.alert}
          color='primary'
          autohide
          position='top-center'
          offset={50}
          delay={3000}
          appendToBody
          onClosed={() => { self.setState({alert: false, alertText: ""})}}
        >{this.state.alertText}</MDBAlert>
      </>

    );
  }
}
export default function FooterComponentFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    return <FooterComponent context={context} searchParams={searchParams} navigate={navigate}/>;
}
